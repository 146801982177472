import i18n from "i18next"
import { initReactI18next } from "react-i18next"
// Import each language JSON file
import enJSON from "./locale/en.json"

i18n.use(initReactI18next).init({
  resources: {
    en: { ...enJSON },
  },
  lng: "en", // Set the initial language of the App
  fallbackLng: "en",
})
