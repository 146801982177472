import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { IUser } from "./types"
import { doc, docExists } from "app/firebase"

interface AuthState {
  user: IUser | null
  loading: boolean
  checkingPermissions: boolean
  isAdmin: boolean
  error: string | null
}

const initialState: AuthState = {
  user: null,
  isAdmin: false,
  loading: true,
  checkingPermissions: true,
  error: null,
}

export const checkIsAdmin = createAsyncThunk(
  "auth/checkIsAdmin",
  async (email: string) => {
    const docRef = doc("config", "admin", "users", email)
    return docExists(docRef)
  },
)

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginStart: (state) => {
      state.loading = true
    },
    loginSuccess: (state, action: PayloadAction<IUser>) => {
      state.loading = false
      state.user = action.payload
      state.error = null
    },
    loginFailure: (state, action: PayloadAction<string>) => {
      state.loading = false
      state.user = null
      state.error = action.payload
    },
    logout: (state) => {
      state.user = null
      state.loading = false
      state.error = null
    },
  },
  extraReducers: (builder) => {
    builder.addCase(checkIsAdmin.pending, (state) => {
      if (state.checkingPermissions === false) {
        state.checkingPermissions = true
      }
    })
    builder.addCase(
      checkIsAdmin.fulfilled,
      (state, action: PayloadAction<boolean>) => {
        state.checkingPermissions = false
        state.isAdmin = action.payload
      },
    )
    builder.addCase(checkIsAdmin.rejected, (state) => {
      state.checkingPermissions = false
      state.isAdmin = false
    })
  },
})

export const { loginStart, loginSuccess, loginFailure, logout } =
  authSlice.actions

export default authSlice.reducer
