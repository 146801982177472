import Button from "@ingka/button"
import Text from "@ingka/text"
import { CircularProgress, Grid } from "@mui/material"
import { useAppDispatch, useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import InfoCard from "components/InfoCard"
import InsightsSummary from "./InsightsSummary"
import InsightsTable from "./InsightsTable"
import PromptCrud from "./PromptCrud"
import { PromptType, SavedPrompt } from "./types"
import { updateInsights, updateInsightsSummary } from "./utils"
import { estimateCost } from "./precalculateCost"
import { useEffect, useState } from "react"

interface ConversationInsightsProps {
  editorMode?: boolean
  insightsPrompts?: SavedPrompt[]
  summaryPrompts?: SavedPrompt[]
  onPromptSave: (selectedPrompt: SavedPrompt, promptType: PromptType) => void
  onAddPrompt: (prompt: SavedPrompt, promptType: PromptType) => void
  onPromptDelete: (promptId: string, promptType: PromptType) => void
}

const ConversationInsights: React.FC<ConversationInsightsProps> = ({
  editorMode = false,
  insightsPrompts,
  summaryPrompts,
  onPromptSave,
  onAddPrompt,
  onPromptDelete,
}) => {
  const dispatch = useAppDispatch()
  const { conversations } = useAppSelector(
    (state: RootState) => state.listConversation,
  )
  const {
    insights,
    insightsLoading,
    selectedInsightPrompt,
    insightsSummaryLoading,
    selectedSummaryPrompt,
  } = useAppSelector((state: RootState) => state.insights)

  const runInsights = async () => {
    if (!selectedInsightPrompt.prompt || !conversations) return
    updateInsights(selectedInsightPrompt, conversations, dispatch)
  }

  const insightsButton = (
    <Button disabled={insightsLoading || !conversations} onClick={runInsights}>
      {insightsLoading ? <CircularProgress color="primary" /> : "Get insights"}
    </Button>
  )

  const runSummary = () => {
    if (!selectedSummaryPrompt.prompt) return
    updateInsightsSummary(selectedSummaryPrompt, insights, dispatch)
  }

  const summaryButton = (
    <Button
      disabled={insightsSummaryLoading || Object.keys(insights).length === 0}
      onClick={runSummary}
    >
      {insightsSummaryLoading ? (
        <CircularProgress color="primary" />
      ) : (
        "Get summary"
      )}
    </Button>
  )

  const isDisabled =
    !editorMode && (!conversations || conversations.length === 0)

  const maxTokens = 2000;
  const [conversationsCost, setConversationsCost] = useState(0);
  const [insightsCost, setInsightsCost] = useState(0);
  const [summaryCost, setSummaryCost] = useState(0);

  let scaleSampleSize: number = 200 / selectedInsightPrompt.amount;

  // Arbitrary scale factors to help make the estimated prompt costs more accurate.
  // Used as the precise process of how the LLM handles the conversation and insights lists was unknown during implementation of the local cost estimation.
  const scaleFactorInsights: number = 1 / 10;

  function calculatePromptCost(item: string, model: string) {
    if (model === "GPT-4o") {
      return scaleFactorInsights * estimateCost(
        "gtp4o",
        item,
        maxTokens
      )
    } else if (model === "GPT-4o Mini") {
      return scaleFactorInsights * estimateCost(
        "gpt4omini",
        item,
        maxTokens
      )
    } else  {
      return 0;
    }
  }

  function calculateInsightsCost() {
    return calculatePromptCost(selectedInsightPrompt.prompt, selectedInsightPrompt.model) + 
    ((conversationsCost / scaleSampleSize) * scaleFactorInsights);
  }

  function displayCost(cost: number, model: string)  {
    if (model === "GPT-4o Mini" || model === "GPT-4o")  {
      return (
        <p style={{color: "#777", fontSize: ".875rem"}}>Estimated cost of prompt: {" "}
          {cost > 0.5 ? <><span style={{fontWeight: "600", color: "rgb(174, 19, 3)"}}>{cost.toFixed(6)} USD</span> (we recommend using <b>GPT4o Mini</b> for the prompt).</>: ""}
          {cost > 0.1 && cost <= 0.5 ? <span style={{fontWeight: "600", color: "rgb(174, 148, 3)"}}>{cost.toFixed(6)} USD</span> : ""}
          {cost <= 0.1 ? <span style={{fontWeight: "600", color: "rgb(7, 140, 28)"}}>{cost.toFixed(6)} USD</span> : ""}
        </p>
      )
    }
  }

  useEffect(() => {
    if (conversations && selectedInsightPrompt) {
      const total = conversations.reduce((sum, conversation) => {
        const concatenatedText = conversation.messages
          .map(message => message.textRaw)
          .join(' ');
        
          let conversationCost: number;

        if (selectedInsightPrompt.model === "GPT-4o") {
          conversationCost = estimateCost(
            "gtp4o",
            selectedInsightPrompt.prompt + concatenatedText,
            maxTokens
          );
        } else if (selectedInsightPrompt.model === "GPT-4o Mini") {
          conversationCost = estimateCost(
            "gpt4omini",
            selectedInsightPrompt.prompt + concatenatedText,
            maxTokens
          );
        } else  {
          return 0;
        }

        return sum + conversationCost;
      }, 0);

      setConversationsCost(total);
    }
  }, [conversations, selectedInsightPrompt, maxTokens]);

  useEffect(() => {
    setInsightsCost(calculateInsightsCost)
    setSummaryCost(
      (
        calculatePromptCost(
          selectedSummaryPrompt.prompt, 
          selectedSummaryPrompt.model
        ) 
        // + calculatePromptCost(
        //   Object.values(insights)
        //   .map(entry => entry.insights)
        //   .join(' '),
        //   selectedSummaryPrompt.model
        // )
      ) 
    )
  })

  return !isDisabled ? (
    <Grid container spacing={2} style={{marginTop: "0rem"}}>
      <Grid item xs={12}>
        <Text tagName="h1" style={{ fontSize: "24px" }}>Conversation insights</Text>
      </Grid>
      <Grid item xs={12}>
        <PromptCrud
          prompts={insightsPrompts}
          promptType="insight"
          promptAction={insightsButton}
          onAddPrompt={onAddPrompt}
          onPromptSave={onPromptSave}
          onPromptDelete={onPromptDelete}
        />
      </Grid>
      <Grid item xs={12}>
        <InsightsTable />
        {displayCost(insightsCost, selectedInsightPrompt.model)}
      </Grid>
      <Grid item xs={12}>
        <Text tagName="h1" style={{ fontSize: "24px" }}>Summary</Text>
      </Grid>
      <Grid item xs={12}>
        <PromptCrud
          prompts={summaryPrompts}
          promptType="summary"
          promptAction={summaryButton}
          onAddPrompt={onAddPrompt}
          onPromptSave={onPromptSave}
          onPromptDelete={onPromptDelete}
        />
      </Grid>
      <Grid item xs={12} style={{marginBottom: "3rem"}}>
        <InsightsSummary />
        {displayCost(summaryCost, selectedSummaryPrompt.model)}
      </Grid>
    </Grid>
  ) : (
    <Grid container justifyContent="center" alignItems="center" height="90vh">
      <InfoCard
        text="You have no conversations to get insights on."
        header="No conversations"
      />
    </Grid>
  )
}

export default ConversationInsights
