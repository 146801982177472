import DOMPurify from "dompurify"

export function purify(obj: any): any {
  if (Array.isArray(obj)) {
    return obj.map(purifyElement)
  }
  return purifyElement(obj)
}

function purifyElement(item: any): any {
  if (typeof item === "string") {
    return DOMPurify.sanitize(item)
  } else if (Array.isArray(item)) {
    return item.map(purifyElement)
  } else if (typeof item === "object" && item !== null) {
    if (item instanceof Date) {
      return item // Dates are safe, no purification needed
    }
    return purifyObject(item)
  }
  return item
}

function purifyObject(obj: any): Record<string, any> {
  const purifiedObj: Record<string, any> = {}

  for (const key in obj) {
    if (Object.hasOwn(obj, key)) {
      purifiedObj[key] = purifyElement(obj[key])
    }
  }

  return purifiedObj
}
