import SSRIcon from "@ingka/ssr-icon"
import ArrowDropDownIcon from "@ingka/ssr-icon/paths/arrow-down"
import ArrowRightIcon from "@ingka/ssr-icon/paths/arrow-right"
import CrossIcon from "@ingka/ssr-icon/paths/cross"
import {
  Box,
  Chip,
  IconButton,
  MenuItem,
  Paper,
  Popover,
  TextField,
  styled,
} from "@mui/material"
import { useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import React, { useState } from "react"

interface TreeNode {
  key: number
  displayName: string
  subNodes?: TreeNode[]
}

interface Tree {
  [key: number]: TreeNode
}

interface LabelTreeSelectProps {
  value: number[]
  onChange: (ids: number[]) => void
  label: string
  placeholder?: string
  multiple?: boolean
  disableSubLevels?: boolean
}

const TreeViewContainer = styled("div")({
  marginLeft: "8px",
  marginRight: "8px",
  paddingBottom: "1rem",
})

const TreeItem = styled(MenuItem)({
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
})

const TreeItemLabel = styled("span")({
  marginLeft: "4px",
  display: "flex",
  width: "100%",
  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.04)",
  },
})

const SubTreeContainer = styled("div")({
  marginLeft: "16px",
  overflow: "hidden",
  transition: "max-height 0.3s ease-out",
})

const isCategory = (label: number) => { return label % 1000 === 0 }
const getCategory = (label: number) => { return label - label % 1000 }

const LabelTreeSelect: React.FC<LabelTreeSelectProps> = ({
  value,
  onChange,
  label,
  placeholder,
  multiple = true,
  disableSubLevels = false,
}) => {
  const { labels } = useAppSelector(
    (state: RootState) => state.listConversation,
  )
  const [expandedNodes, setExpandedNodes] = useState<number[]>([])
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const createTree = (labels: any): Tree => {
    const tree: Tree = {}
    // First, create the main nodes
    Object.keys(labels).forEach((key) => {
      const numKey = Number(key)
      if (numKey % 1000 === 0) {
        tree[numKey] = {
          key: numKey,
          displayName: labels[numKey].displayName,
          subNodes: [],
        }
      }
    })
    // Then, populate the main nodes with subnodes
    Object.keys(labels).forEach((key) => {
      const numKey = Number(key)
      if (numKey % 1000 !== 0) {
        const mainNodeKey = numKey - (numKey % 1000)
        if (tree[mainNodeKey]) {
          tree[mainNodeKey].subNodes?.push({
            key: numKey,
            displayName: labels[numKey].displayName,
          })
        }
      }
    })
    return tree
  }

  const tree = createTree(labels)

  const handleNodeToggle = (nodeId: number) => {
    setExpandedNodes((prev) =>
      prev.includes(nodeId)
        ? prev.filter((id) => id !== nodeId)
        : [...prev, nodeId],
    )
  }

  const handleNodeSelect = (nodeId: number) => {
    const category = getCategory(nodeId)
    const topicIds = isCategory(nodeId) && multiple ? Object.keys(labels)
        .map(x => Number(x))
        .filter(x => getCategory(x) === category && !isCategory(x)) : [nodeId]
    const filtered = value.filter(x => !topicIds.includes(x))

    if (filtered.length !== value.length) {
      onChange(filtered)
    } else if (multiple) {
      if (value.length > 10) return
      onChange([...value, ...topicIds])
    } else {
      onChange(topicIds)
    }
  }

  const handleInputClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleDelete = (nodeId: number) => {
    onChange(value.filter((id) => id !== nodeId))
  }

  const isAnySubNodeSelected = (subNodes: TreeNode[] | undefined) => {
    return subNodes?.some((subNode) => value.includes(subNode.key))
  }

  const renderTree = (nodes: Tree) => (
    <TreeViewContainer>
      {Object.keys(nodes).map((key) => {
        const numKey = Number(key)
        const node = nodes[numKey]
        const subNodesSelected = isAnySubNodeSelected(node.subNodes)

        return (
          <div key={numKey}>
            <TreeItem
              sx={{
                backgroundColor: value.includes(numKey)
                  ? "#0058a3"
                  : subNodesSelected
                    ? "#dfdfdf"
                    : "inherit",
                "&:hover": {
                  backgroundColor: "#003E72",
                  color: "white",
                },
              }}
            >
              {!disableSubLevels &&
                node.subNodes &&
                node.subNodes.length > 0 &&
                (expandedNodes.includes(numKey) ? (
                  <SSRIcon
                    onClick={() => handleNodeToggle(numKey)}
                    paths={ArrowDropDownIcon}
                    className="entityIcon"
                  />
                ) : (
                  <SSRIcon
                    onClick={() => handleNodeToggle(numKey)}
                    paths={ArrowRightIcon}
                    className="entityIcon"
                  />
                ))}
              <TreeItemLabel onClick={() => handleNodeSelect(numKey)}>
                {node.displayName}
              </TreeItemLabel>
            </TreeItem>
            <SubTreeContainer
              style={{
                maxHeight: expandedNodes.includes(numKey) ? "500px" : "0",
              }}
            >
              {node.subNodes?.map((subNode) => (
                <TreeItem
                  key={subNode.key}
                  onClick={() => handleNodeSelect(subNode.key)}
                  sx={{
                    backgroundColor: value.includes(subNode.key)
                      ? "#0058a3"
                      : "inherit",
                    "&:hover": {
                      backgroundColor: "#003E72",
                      color: "white",
                    },
                    paddingLeft: "24px",
                  }}
                >
                  <TreeItemLabel>
                    {subNode.displayName.split("::")[1]}
                  </TreeItemLabel>
                </TreeItem>
              ))}
            </SubTreeContainer>
          </div>
        )
      })}
    </TreeViewContainer>
  )

  return (
    <>
      <TextField
        onClick={handleInputClick}
        placeholder={placeholder}
        aria-readonly
        fullWidth
        label={label}
        InputProps={{
          startAdornment: (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {value.map((nodeId) => (
                <Chip
                  key={nodeId}
                  label={labels[nodeId]?.displayName ?? nodeId}
                  onDelete={() => handleDelete(nodeId)}
                />
              ))}
            </Box>
          ),
          endAdornment: (
            <IconButton
              size="small"
              onClick={(e: any) => {
                e.preventDefault()
                e.stopPropagation()
                onChange([])
              }}
            >
              <SSRIcon paths={CrossIcon} />
            </IconButton>
          ),
        }}
      />
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            style: {
              maxHeight: "35rem",
              overflowY: "auto",
            },
          },
        }}
      >
        <Paper>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton size="small" onClick={handleClose}>
              <SSRIcon paths={CrossIcon} />
            </IconButton>
          </div>
          {renderTree(tree)}
        </Paper>
      </Popover>
    </>
  )
}

export default LabelTreeSelect
