import { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import { fetchFlags } from "../flagSlice"

export const useFetchFlags = () => {
  const dispatch = useAppDispatch()
  const { loading, flags } = useAppSelector((state: RootState) => state.flag)

  useEffect(() => {
    const fetchFlagsIfNotLoaded = async () => {
      if (!flags && !loading) {
        await dispatch(fetchFlags())
      }
    }

    fetchFlagsIfNotLoaded()
  }, [dispatch, loading, flags])
}
