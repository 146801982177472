import Button from "@ingka/button"
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material"
import React, { ChangeEvent } from "react"
import { AdminUser } from "./types"

const AddUserDialog: React.FC<{
  open: boolean
  onClose: () => void
  newUser: AdminUser
  onInputChange: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: keyof AdminUser,
  ) => void
  onAddUser: () => void
  saving: boolean
  emailError: string
  descriptionError: string
}> = ({
  open,
  onClose,
  newUser,
  onInputChange,
  onAddUser,
  saving,
  emailError,
  descriptionError,
}) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>Add Admin User</DialogTitle>
    <DialogContent>
      <TextField
        label="Email"
        name="email"
        value={newUser.email}
        onChange={(e) => onInputChange(e, "email")}
        fullWidth
        sx={{ marginBottom: 2 }}
        error={!!emailError}
        helperText={emailError}
      />
      <TextField
        label="Description"
        name="description"
        value={newUser.description}
        onChange={(e) => onInputChange(e, "description")}
        fullWidth
        sx={{ marginBottom: 2 }}
        inputProps={{ maxLength: 100 }}
        error={!!descriptionError}
        helperText={descriptionError}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} disabled={saving}>
        Cancel
      </Button>
      <Button type="emphasised" onClick={onAddUser} disabled={saving}>
        {saving ? <CircularProgress size={24} /> : "Add"}
      </Button>
    </DialogActions>
  </Dialog>
)

export default AddUserDialog
