import { market } from "app/types"
import { EntityZipLocalStorageObject } from "./types"
import {
  getLocalStorageData,
  isOlderThan7Days,
  updateEntitiesLocalStorage,
} from "./utils"

export const getEntitiesZip = async (market: market): Promise<string> => {
  const key = `${market}_entities`
  const storedData = getLocalStorageData<EntityZipLocalStorageObject>(key)

  if (!storedData || isOlderThan7Days(storedData.timestamp)) {
    return updateEntitiesLocalStorage(market)
  }
  return storedData.entityZip
}
