import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { doc, getDoc } from "app/firebase"
import { Flag, FlagState } from "./types"
import { saveFlagOnConversation } from "app/api"

const initialState: FlagState = {
  loading: false,
  saving: false,
}

export const fetchFlags = createAsyncThunk("flag/fetchFlags", async () => {
  const flagsRef = doc("config", "flags")
  const flagsDoc = await getDoc(flagsRef)

  if (!flagsDoc?.data?.flagNames) {
    return []
  }

  return flagsDoc.data.flagNames
})

export const createFlagOnConversation = createAsyncThunk(
  "flag/createFlagOnConversation",
  async ({
    conversationId,
    flagName,
  }: {
    conversationId: string
    flagName: string
  }) => {
    const newFlag: Flag = {
      conversationId,
      name: flagName,
    }

    await saveFlagOnConversation(newFlag)
    return newFlag
  },
)

export const flagSlice = createSlice({
  name: "flag",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchFlags.pending, (state) => {
      if (state.loading === false) {
        state.loading = true
      }
    })
    builder.addCase(
      fetchFlags.fulfilled,
      (state, action: PayloadAction<string[]>) => {
        state.flags = action.payload
        state.loading = false
      },
    )
    builder.addCase(fetchFlags.rejected, (state) => {
      if (state.loading === true) {
        state.loading = false
      }
    })
    builder.addCase(createFlagOnConversation.pending, (state) => {
      if (state.saving === false) {
        state.saving = true
      }
    })
    builder.addCase(createFlagOnConversation.fulfilled, (state) => {
      state.saving = false
    })
    builder.addCase(createFlagOnConversation.rejected, (state) => {
      if (state.saving === true) {
        state.saving = false
      }
    })
  },
})

export default flagSlice.reducer
