import Banner from "@ingka/banner"
import Check from "@mui/icons-material/Check"
import { useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import { IConversation } from "app/types"
import React from "react"

interface GetLabelBannerProps {
  conversation: IConversation
  showManualTagId: boolean
}

const GetLabelBanner: React.FC<GetLabelBannerProps> = ({
  conversation,
  showManualTagId,
}) => {
  const { isAdmin } = useAppSelector((state: RootState) => state.auth)
  const { labels } = useAppSelector(
    (state: RootState) => state.listConversation,
  )

  const smallCheck = <Check style={{ fontSize: "12px" }} />

  let body: string
  let bgColor: string = "#eaeaea"
  const aiIsCorrect =
    conversation.review?.correctLabelId === conversation.autoLabelId
  const manualIsCorrect =
    conversation.review?.correctLabelId === conversation.manualLabelId
  const llmIsCorrect =
    conversation.review?.correctLabelId === conversation.llmLabelId

  if (isAdmin) {
    let aiBgColor = labels[conversation.autoLabelId]?.color ?? "black"
    let manualBgColor = labels[conversation.manualLabelId]?.color ?? "black"
    let llmBgColor = labels[conversation.llmLabelId]?.color ?? "black"
    return (
      <>
        <Banner
          className="tagBanner"
          body={
            <>
              {manualIsCorrect && smallCheck}
              {`Co-worker tag: ${labels[conversation.manualLabelId]?.displayName ?? conversation.manualLabelId ?? "None"}`}
            </>
          }
          style={{
            backgroundColor: manualBgColor,
            color: manualBgColor === "black" ? "white" : "black",
          }}
        />
        <Banner
          className="tagBanner"
          body={
            <>
              {aiIsCorrect && smallCheck}
              {`AI tag: ${labels[conversation.autoLabelId]?.displayName ?? conversation.autoLabelId ?? "None"}`}
            </>
          }
          style={{
            backgroundColor: aiBgColor,
            color: aiBgColor === "black" ? "white" : "black",
          }}
        />
        {Boolean(conversation.llmLabelId) && (
          <Banner
            className="tagBanner"
            body={
              <>
                {llmIsCorrect && smallCheck}
                {`LLM tag: ${labels[conversation.llmLabelId]?.displayName ?? conversation.llmLabelId ?? "None"}`}
              </>
            }
            style={{
              backgroundColor: llmBgColor,
              color: llmBgColor === "black" ? "white" : "black",
            }}
          />
        )}
      </>
    )
  }

  let showCheck
  if (showManualTagId) {
    if (conversation.manualLabelId) {
      body = `Co-worker tag: ${labels[conversation.manualLabelId]?.displayName ?? conversation.manualLabelId ?? "None"}`
      bgColor = labels[conversation.manualLabelId]?.color ?? "black"
    } else {
      body = "No co-worker tag"
    }
    showCheck = manualIsCorrect
  } else if (conversation.autoLabelId) {
    body = `AI tag: ${labels[conversation.autoLabelId]?.displayName ?? conversation.autoLabelId ?? "None"}`
    bgColor = labels[conversation.autoLabelId]?.color ?? "black"
    showCheck = aiIsCorrect
  } else {
    body = "No AI tag"
    showCheck = false
  }

  return (
    <Banner
      className="tagBanner"
      body={
        <>
          {showCheck && smallCheck}
          {body}
        </>
      }
      style={{
        backgroundColor: bgColor,
        color: bgColor === "black" ? "white" : "black",
      }}
    />
  )
}

export default GetLabelBanner
