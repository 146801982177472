import Slider from "@ingka/slider"
import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material"
import { useAppDispatch, useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import { setAmount, setModel } from "./insightsSlice"
import { LLMModel, PromptType } from "./types"

const InsightsControls = ({ promptType }: { promptType: PromptType }) => {
  const dispatch = useAppDispatch()
  const {
    selectedInsightPrompt: selectedInsightsPrompt,
    selectedSummaryPrompt,
  } = useAppSelector((state: RootState) => state.insights)

  const model =
    promptType == "summary"
      ? selectedSummaryPrompt.model
      : selectedInsightsPrompt.model
  const amount = selectedInsightsPrompt.amount
    // promptType === "summary"
    //   ? selectedSummaryPrompt.amount
    //   : selectedInsightsPrompt.amount

  return (
    <Grid container item xs={12} justifyContent="space-around" margin={2}>
      <Grid item xs={6}>
        <div style={promptType === "summary" ? {visibility: "hidden"} : {}}>
          <Slider
            id="amountSlider"
            ariaFormat={{
              from: function noRefCheck() {},
              to: function noRefCheck() {},
            }}
            start={amount || 20}
            range={{ min: 0, max: 200 }}
            step={10}
            onChange={(valueArray: number[]) =>
              dispatch(
                setAmount({
                  amount: valueArray.length ? valueArray[0] : 20,
                  promptType,
                }),
              )
            }
            label=""
            ariaLabels={"Selected value"}
            style={{marginTop: "-2rem"}}
          />
          <p style={{color: "#777", fontSize: ".875rem", marginTop: "1.5rem"}}>Number of sampled conversations</p>
        </div>
      </Grid>
      <Grid
        item
        xs={4}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <FormControl fullWidth>
          <InputLabel id="version-select-label">Version</InputLabel>
          <Select
            labelId="version-select-label"
            id="version-select"
            value={model ?? "GPT-3.5"}
            label="Version"
            onChange={(e) =>
              dispatch(
                setModel({ model: e.target.value as LLMModel, promptType }),
              )
            }
          >
            {/* <MenuItem value={"GPT-3.5" as LLMModel}>GPT3.5</MenuItem>
            <MenuItem value={"GPT-4.0" as LLMModel}>GPT4.0</MenuItem> */}
            <MenuItem value={"GPT-4o" as LLMModel}>GPT4o</MenuItem>
            <MenuItem value={"GPT-4o Mini" as LLMModel}>GPT4o Mini</MenuItem>
            <MenuItem value={"Gemini-1.5-Pro" as LLMModel}>
              Gemini-1.5 Pro
            </MenuItem>
            {/* <MenuItem value={"Mixtral" as LLMModel}>Mixtral-8x7b</MenuItem> */}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  )
}

export default InsightsControls
