import { httpsCallable } from "firebase/functions"
import { functions } from "./firebase"
import {
  IConversationResponse,
  IConversationReview,
  KeyValueType,
  market,
} from "./types"
import {
  InsightPromptRequest,
  SummaryPromptRequest,
} from "features/insights/types"
import { Flag } from "features/flag/types"

export const listConversations = httpsCallable<
  KeyValueType[],
  IConversationResponse
>(functions, "listConversations")

export const conversationInsights = httpsCallable<InsightPromptRequest, any>(
  functions,
  "conversationInsights",
  {
    timeout: 120000,
  },
)

export const insightsSummary = httpsCallable<SummaryPromptRequest, any>(
  functions,
  "insightsSummary",
  {
    timeout: 120000,
  },
)

export const saveReview = httpsCallable<IConversationReview, void>(
  functions,
  "saveReview",
)

export const saveFlagOnConversation = httpsCallable<Flag, void>(
  functions,
  "saveFlagOnConversation",
)

export const getEntitiesZip = httpsCallable<market, string>(
  functions, 
  "getEntitiesZip"
)
