import { Box, Grid } from "@mui/material"
import Header from "components/Header"
import LoadingView from "components/Loading"
import { Navigate, useLocation } from "react-router-dom"
import { useAppSelector } from "./hooks"
import { RootState } from "./store"

const AdminRoute = ({ children }: { children: JSX.Element }) => {
  let location = useLocation()

  const { user, loading, checkingPermissions, isAdmin } = useAppSelector(
    (state: RootState) => state.auth,
  )

  if (loading || checkingPermissions) {
    return <LoadingView />
  }

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} />
  }

  if (!isAdmin) {
    return <Navigate to="/" state={{ from: location }} />
  }

  return (
    <Grid container className="listConversationWindow">
      <Header />
      <Box style={{maxHeight: "calc(100vh - 4rem)", height: "100%", overflowY: "scroll", width: "100%"}}>
      <Grid xs={12} item>
        {children}
      </Grid>
      </Box>
    </Grid>
  )
}

export default AdminRoute
