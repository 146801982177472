import Button from "@ingka/button"
import InlineMessage from "@ingka/inline-message"
import informationCircle from "@ingka/ssr-icon/paths/information-circle"
import Text from "@ingka/text"
import Card from "@mui/material/Card"
import CardActions from "@mui/material/CardActions"
import CardContent from "@mui/material/CardContent"
import CardMedia from "@mui/material/CardMedia"
import { auth } from "app/firebase"
import { useAppDispatch, useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import microsoftLogo from "assets/Microsoft_icon.svg"
import mosaikLogo from "assets/MosaikLogo.svg"
import { OAuthProvider, signInWithPopup } from "firebase/auth"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router-dom"
import { loginFailure, loginStart, loginSuccess } from "./authSlice"
import "./login.css"
import { IUser } from "./types"

export type RedirectLocationState = {
  from: Location
}

export const provider = new OAuthProvider("microsoft.com")
provider.setCustomParameters({
  tenant: import.meta.env.VITE_FIREBASE_AZURE_TENANT,
})

const Login = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { state: locationState } = useLocation()
  const { user, loading, error } = useAppSelector(
    (state: RootState) => state.auth,
  )

  const loginWithMicrosoft = () => async () => {
    dispatch(loginStart())
    try {
      const result = await signInWithPopup(auth, provider)
      const user: IUser = {
        displayName: result.user.displayName,
        email: result.user.email,
        id: result.user.uid,
      }
      dispatch(loginSuccess(user))
    } catch (error: any) {
      console.log(error)
      dispatch(loginFailure(error.message))
    }
  }

  useEffect(() => {
    if (user) {
      if (locationState) {
        const { from } = locationState as RedirectLocationState
        navigate(`${from.pathname}${from.search}`)
      } else {
        navigate("/")
      }
    }
  }, [user, navigate, locationState])

  const handleLogin = () => {
    dispatch(loginWithMicrosoft())
  }

  const actions = (
    <CardActions className="loginActions">
      <Button
        loading={loading}
        disabled={loading}
        type="emphasised"
        onClick={handleLogin}
      >
        <img className="msIcon" src={microsoftLogo} alt="ms" />
        {t("login:login")}
      </Button>
    </CardActions>
  )

  const content = (
    <CardContent className="loginContent">
      <Text tagName="h2">{t("login:cardHeader")}</Text>
      <Text tagName="h5" color="text.secondary">
        {t("login:cardBody")}
      </Text>
    </CardContent>
  )

  const errorMessage = (
    <InlineMessage
      title={t("login:failureHeader")}
      body={t("login:failureBody")}
      variant="negative"
      ssrIcon={informationCircle}
      dismissable
    />
  )

  return (
    <Card className="loginCard">
      <CardMedia
        sx={{ height: 200, width: 500 }}
        image={mosaikLogo}
        title="Tagging"
      />
      {content}
      {actions}
      {error && errorMessage}
    </Card>
  )
}

export default Login
