import Loading, { LoadingBall } from "@ingka/loading"
import Backdrop from "@mui/material/Backdrop"

const LoadingView = () => {
  return (
    <Backdrop
      sx={{
        backgroundColor: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={true}
    >
      <Loading color="primary" labelTransitions>
        <LoadingBall size="large" />
      </Loading>
    </Backdrop>
  )
}

export default LoadingView
