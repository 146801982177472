import { collection, doc, getDoc, getDocs } from "app/firebase"
import { SavedPrompt } from "./types"

export const getPrompts = async (path: string) => {
  const pre = doc(path)
  const snap = await getDoc(pre)
  const data = snap?.data
  return data?.prompts || []
}

export const getAllPrompts = async (
  collectionPath: string,
): Promise<SavedPrompt[]> => {
  const collectionRef = collection(collectionPath)
  const snap = await getDocs(collectionRef)
  const prompts: SavedPrompt[] = []

  snap.forEach((doc) => {
    const data = doc.data
    if (data) {
      prompts.push({ ...data, id: doc.id } as SavedPrompt)
    }
  })

  return prompts
}
