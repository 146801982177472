import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import MarkdownPreview from '@uiw/react-markdown-preview';
import rehypeSanitize from "rehype-sanitize";

interface SummaryDialogProps {
  open: boolean
  onClose: () => void
  loading: boolean
  summary: string
}

const rehypePlugins = [rehypeSanitize];

const SummaryDialog: React.FC<SummaryDialogProps> = ({
  open,
  onClose,
  loading,
  summary,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Insights Summary</DialogTitle>
      <DialogContent>
        {loading ? <CircularProgress /> : <MarkdownPreview source={summary} rehypePlugins={rehypePlugins} style={{ padding: 16 }} />}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SummaryDialog
