import { saveReview } from "app/api"
import { IConversationReview } from "app/types"
import { ConversationReview } from "./types"

export async function saveConversationReview(review: ConversationReview) {
  const reviewToSave: IConversationReview = {
    user_id: review.userId,
    published: review.published,
    correct_label_id: review.correctLabelId,
    comment: review.comment,
    country: review.country,
    conversation_id: review.conversationId,
    label_id: review.labelId,
    model_id: review.modelId,
  }

  await saveReview(reviewToSave)
}

export async function deleteConversationReview(
  userId: string,
  conversationId: string,
  country: string,
) {
  const reviewToSave: IConversationReview = {
    user_id: userId,
    published: null,
    correct_label_id: null,
    comment: null,
    country: country,
    conversation_id: conversationId,
    label_id: null,
    model_id: null,
  }

  await saveReview(reviewToSave)
}
