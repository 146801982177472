import { useFetchConversations } from "./hooks/useFetchConversations"
import { useSetInitialSelectedConversation } from "./hooks/useSetInitialSelectedConversation"
import { useFetchLabels } from "./hooks/useFetchLabels"
import Grid from "@mui/material/Grid"
import { useAppSelector } from "app/hooks"
import InfoCard from "components/InfoCard"
import MessageList from "../messages/MessageList"
import ConversationFilterMenu from "./ConversationFilterMenu"
import ConversationList from "./ConversationList"
import { RootState } from "app/store"
import { useFetchFlags } from "../flag/hooks/useFetchFlags"
import { Container } from "@mui/material"

const ListConversations: React.FC = () => {
  useFetchConversations()
  useSetInitialSelectedConversation()
  useFetchLabels()
  useFetchFlags()

  const {
    conversations,
    loadingConversations,
    filters,
    totalAmountOfConversations,
  } = useAppSelector((state: RootState) => state.listConversation)
  const { insights } = useAppSelector((state: RootState) => state.insights)

  return (
    <Container maxWidth="xl"> 
        {!filters.length && !loadingConversations ? (
          <Grid container justifyContent="center" alignItems="center" height="90vh">
            <InfoCard
              text={
                <>
                  You need to at least filter on country
                  <ConversationFilterMenu text="Filter menu" fluid={true} />
                </>
              }
              header="No filters"
            />
          </Grid>
        ) : (
          <>
            <div style={{display: "flex", flexDirection: "row", gap: "2rem"}}>
              <div style={{width: "50%"}}>
                <ConversationList
                  filters={filters}
                  conversations={conversations ?? []}
                  conversationInsights={insights}
                  totalAmountOfConversations={totalAmountOfConversations}
                />
              </div>
              <div style={{width: "50%", position: "relative", zIndex: "1"}}>
                {/* <div style={{position: "fixed", maxWidth: "52rem", width: "calc(50vw - 2.5rem"}}> */}
                <MessageList />
                {/* </div> */}
              </div>
            </div>
          </>
        )}
    </Container>
  )
}

export default ListConversations
