import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { IConversation } from "app/types"
import { deleteConversationReview, saveConversationReview } from "./api"
import { ConversationReview } from "./types"

export const delConversationReview = createAsyncThunk(
  "conversationReview/delete",
  async ({
    userId,
    conversationId,
    country,
  }: {
    userId: string
    conversationId: string
    country: string
  }) => {
    await deleteConversationReview(userId, conversationId, country)
    return conversationId
  },
)

export const createConversationReview = createAsyncThunk(
  "conversationReview/create",
  async ({
    conversation,
    userId,
    correctLabelId,
    comment,
  }: {
    conversation: IConversation
    userId: string
    correctLabelId: number
    comment: string
  }) => {
    const newReview: ConversationReview = {
      userId: userId,
      published: null,
      correctLabelId,
      comment,
      country: conversation.country,
      conversationId: conversation.id,
      labelId: conversation.autoLabelId ?? null,
      modelId: null,
    }

    await saveConversationReview(newReview)
    return correctLabelId
  },
)

const initialState = {
  saving: false,
}
const conversationReviewSlice = createSlice({
  name: "conversationReview",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // eslint-disable-next-line no-unused-vars
    builder.addCase(createConversationReview.pending, (state) => {
      if (state.saving === false) {
        state.saving = true
      }
    })
    builder.addCase(createConversationReview.fulfilled, (state) => {
      state.saving = false
    })
    builder.addCase(createConversationReview.rejected, (state) => {
      if (state.saving === true) {
        state.saving = false
      }
    })
    // eslint-disable-next-line no-unused-vars
    builder.addCase(delConversationReview.pending, (state) => {
      if (state.saving === false) {
        state.saving = true
      }
    })
    builder.addCase(delConversationReview.fulfilled, (state) => {
      state.saving = false
    })
    builder.addCase(delConversationReview.rejected, (state) => {
      if (state.saving === true) {
        state.saving = false
      }
    })
  },
})

export default conversationReviewSlice.reducer
