import "@ingka/table/dist/style.css"
import { Container } from "@mui/material"
import { useAppDispatch, useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import ConversationInsights from "features/insights/ConversationInsights"
import {
  addSavedPrompt,
  deleteSavedPrompt,
  fetchSavedPrompts,
  saveCurrentPrompt,
} from "features/insights/insightsSlice"
import { PromptType, SavedPrompt } from "features/insights/types"
import { useEffect } from "react"

const AdminUserPrompting = () => {
  const dispatch = useAppDispatch()
  const { user } = useAppSelector((state: RootState) => state.auth)
  const { savedInsightsPrompts, savedSummaryPrompts } = useAppSelector(
    (state: RootState) => state.insights,
  )

  useEffect(() => {
    if (user) {
      dispatch(fetchSavedPrompts({ collectionIdentifier: user.id }))
    }
  }, [dispatch, user])

  const onPromptDelete = (promptId: string, promptType: PromptType) => {
    if (!user) return
    dispatch(
      deleteSavedPrompt({
        collectionIdentifier: user.id,
        promptType,
        promptId,
      }),
    )
  }

  const onPromptSave = (
    selectedPrompt: SavedPrompt,
    promptType: PromptType,
  ) => {
    if (!user) return
    dispatch(
      saveCurrentPrompt({
        collectionIdentifier: user?.id,
        prompt: selectedPrompt,
        promptType,
      }),
    )
  }

  const onAddPrompt = (prompt: SavedPrompt, promptType: PromptType) => {
    if (!user) return
    dispatch(
      addSavedPrompt({
        prompt,
        collectionIdentifier: user?.id,
        promptType,
      }),
    )
  }

  return (
    user && (
      <Container maxWidth="xl"> 
        <ConversationInsights
          insightsPrompts={savedInsightsPrompts}
          summaryPrompts={savedSummaryPrompts}
          onAddPrompt={onAddPrompt}
          onPromptSave={onPromptSave}
          onPromptDelete={onPromptDelete}
        />
      </Container>
    )
  )
}

export default AdminUserPrompting
