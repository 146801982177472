import { Dispatch } from "redux"
import {
  fetchConversationInsights,
  fetchInsightsSummary,
} from "./insightsSlice"
import { ConversationInsight, SavedPrompt } from "./types"

export const updateInsights = async (
  prompt: SavedPrompt,
  conversations: any[],
  dispatch: Dispatch<any>,
  labelId?: number,
) => {
  if (!prompt?.prompt) return

  const maxIterations = Math.min(prompt.amount, conversations.length)
  const messages: Record<string, string> = {}
  for (let i = 0; i < maxIterations; i++) {
    const conversation = conversations[i]
    let message = ""
    for (const mess of conversation.messages) {
      message += `<${mess.userType}>: <${mess.text ?? mess.textRaw}> \\n`
    }
    messages[conversation.id] = message
  }
  if (labelId) {
    dispatch(
      fetchConversationInsights({
        messages,
        labelId: labelId,
        promptId: prompt.id,
      }),
    )
    return
  }
  dispatch(
    fetchConversationInsights({
      messages,
      systemPrompt: prompt.prompt,
      version: prompt.model,
    }),
  )
}

export const updateInsightsSummary = (
  prompt: SavedPrompt,
  insights: Record<string, ConversationInsight>,
  dispatch: Dispatch<any>,
  labelId?: number,
) => {
  if (!prompt?.prompt) return
  const insightsValues = Object.values(insights).map((i: any) => "<" + i.insights + ">")

  if (labelId) {
    dispatch(
      fetchInsightsSummary({
        insights: insightsValues,
        labelId: labelId,
        promptId: prompt.id,
      }),
    )
    return
  }
  dispatch(
    fetchInsightsSummary({
      insights: insightsValues,
      version: prompt.model,
      systemPrompt: prompt.prompt,
    }),
  )
}
