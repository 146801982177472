import HelperText from "@ingka/helper-text"
import InlineMessage from "@ingka/inline-message"
import Text from "@ingka/text"
import { Pagination, Skeleton } from "@mui/material"
import Grid from "@mui/material/Grid"
import { useAppDispatch, useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import { IConversation, KeyValueType } from "app/types"
import ConversationSearch from "features/conversationSearch/ConversationSearch"
import PrePromptedInsights from "features/insights/PrePromptedInsights"
import PrePromptedSummary from "features/insights/PrePromptedSummary"
import { ConversationInsight } from "features/insights/types"
import { ChangeEvent, useCallback, useMemo } from "react"
import { purify } from "utils/sanitize"
import { VList } from "virtua"
import ConversationItem from "./ConversationItem"
import ConversationsSkeleton from "./ConversationsSkeleton"
import { fetchConversations, resetErrorMessage } from "./listConversationSlice"
import "./styles/conversationList.css"

interface ConversationListProps {
  conversations: IConversation[]
  conversationInsights: Record<string, ConversationInsight>
  totalAmountOfConversations: number
  filters: KeyValueType[]
}

const ConversationList: React.FC<ConversationListProps> = ({
  conversations,
  conversationInsights,
  totalAmountOfConversations,
  filters,
}) => {
  const dispatch = useAppDispatch()
  const { loadingConversations, errorMessage } = useAppSelector(
    (state: RootState) => state.listConversation,
  )

  const showManualTag: boolean = filters.some(
    (filter) => filter.key === "manual_tag_label_id",
  )

  const labelId = useMemo(() => {
    const labelFilters = filters.filter((filter) =>
      filter.key.endsWith("label_id"),
    )

    if (labelFilters.length === 1) {
      const value = parseInt(labelFilters[0].value, 10)
      return isNaN(value) ? undefined : value - (value % 1000)
    }

    return undefined
  }, [filters])

  const elements = useMemo(
    () =>
      conversations.map((conversation) => (
        <div key={conversation.id}>
          <ConversationItem
            showManualTagId={showManualTag}
            conversation={conversation}
            insight={conversationInsights[conversation.id]}
          />
        </div>
      )),
    [conversations, conversationInsights, showManualTag],
  )

  const onPagination = useCallback(
    (_event: ChangeEvent<unknown>, page: number) => {
      const pageParam: KeyValueType = {
        key: "page",
        value: purify(page),
      }
      const filtersWithParam = [...filters, pageParam]

      dispatch(fetchConversations(filtersWithParam))
    },
    [filters, dispatch],
  )

  return (
    <Grid container flexDirection="column">
      {errorMessage && (
        <div className="overlay-container">
          <InlineMessage
            body={errorMessage}
            title="Couldn't fetch conversations"
            variant="cautionary"
            dismissable
            onDismissClick={() => dispatch(resetErrorMessage())}
          />
        </div>
      )}
      <Grid item xs={12}>
        <Text tagName="h1" style={{fontSize: "24px", marginTop: "2.25rem"}}>Conversation sample</Text>
        <Text tagName="p" className="color-darkGrey" style={{margin: "-1rem 0 1.7rem 0"}}>
          Showing a sample of {conversations.length} conversations from {totalAmountOfConversations} in total, filtered by:
        </Text>
        <div className="flex_row" style={{margin: "0 0 1.5rem 0"}}>
          {filters.map(({ key, value }, index) => (
            <button className="pill pill--small" aria-pressed="false" key={index}>
              <span className="pill__label">
                {key
                .replace(/\b\w/g, (char) => char.toUpperCase())
                .replace(/_/g, ' ')}
                : 
                {" " + value}
              </span>
            </button>
          ))}
        </div>
      </Grid>
      <Grid
        item
        xs={12}
        container
        display="flex"
        sx={{ overflow: "hidden" }}
        gap=".5rem"
      >
        {!loadingConversations ? (
          <>
            {!labelId && (
              <Grid item xs={12}>
                <HelperText>
                  Prompting is only available when filtered on one label
                </HelperText>
              </Grid>
            )}
            <div style={{display: "flex", flexDirection: "row", width: "100%", marginBottom: ".5rem", gap: ".5rem"}}>
              <Grid item xs={6} maxHeight="1.5rem" marginBottom="0.5rem">
                <PrePromptedInsights labelId={labelId} />
              </Grid>
              <Grid item xs={6} maxHeight="1.5rem" marginBottom="0.5rem">
                <PrePromptedSummary labelId={labelId} />
              </Grid>
            </div>
          </>
        ) : (
          <>
            <Grid item xs={12}>
              <Skeleton height="calc(24rem/16)" width="30%" />
            </Grid>
            <div style={{display: "flex", flexDirection: "row", width: "100%", marginBottom: ".5rem", gap: ".5rem"}}>
            <Skeleton height="calc(36rem/16)" width="calc(50% - .25rem)" />
            <Skeleton height="calc(36rem/16)" width="calc(50% - .25rem)" />
            </div>
          </>
        )}
      </Grid>
      <Grid
        item
        xs={12}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className="conversationsOptionsRow"
        maxHeight="2rem"
        marginBottom="1.75rem"
      >
        <ConversationSearch />
      </Grid>
      <Grid item xs={12}>
        {loadingConversations ? (
          <ConversationsSkeleton />
        ) : (
          <VList style={{ height: "calc(100vh - 30rem)" }}>{elements}</VList>
        )}
      </Grid>
      {totalAmountOfConversations !== 0 && (
        <Grid item xs={12} container justifyContent="center" style={{marginTop: "1.75rem"}}>
          <Pagination
            onChange={onPagination}
            count={Math.ceil(totalAmountOfConversations / 200)}
            size="small"
          />
        </Grid>
      )}
    </Grid>
  )
}

export default ConversationList
