import SSRIcon from "@ingka/ssr-icon"
import clock from "@ingka/ssr-icon/paths/clock"
import documentIcon from "@ingka/ssr-icon/paths/document"
import speechBubble from "@ingka/ssr-icon/paths/speech-bubble"
import Text from "@ingka/text"
import InsertEmoticonOutlinedIcon from "@mui/icons-material/InsertEmoticonOutlined"
import SentimentDissatisfiedSharpIcon from "@mui/icons-material/SentimentDissatisfiedSharp"
import SentimentNeutralOutlinedIcon from "@mui/icons-material/SentimentNeutralOutlined"
import Grid from "@mui/material/Grid"
import { IConversation } from "app/types"
import "./styles/entity.css"

const csatIcon = (score: number) => {
  if (score === 3) {
    return (
      <SentimentNeutralOutlinedIcon color={"warning"} className="entityIcon" />
    )
  }
  if (score > 3) {
    return (
      <InsertEmoticonOutlinedIcon color={"success"} className="entityIcon" />
    )
  }
  if (score < 3) {
    return (
      <SentimentDissatisfiedSharpIcon color={"error"} className="entityIcon" />
    )
  }
}

const EntityBar = ({ conversation }: { conversation: IConversation }) => {
  return (
    <Grid container item xs={12} className="entityBar">
      {conversation.csatScore && (
        <Grid item sm={3} xs={6}>
          <Text bodySize="s" tagName="p" className="entityItem">
            {csatIcon(conversation.csatScore)}
            {conversation.csatScore}
          </Text>
        </Grid>
      )}
      <Grid item sm={3} xs={6}>
        <Text bodySize="s" tagName="p" className="entityItem">
          <SSRIcon paths={clock} className="entityIcon" />
          {conversation.startTime.split("T")[0]}
        </Text>
      </Grid>
      <Grid item sm={3} xs={6}>
        <Text bodySize="s" tagName="p" className="entityItem">
          <SSRIcon paths={speechBubble} className="entityIcon" />
          Channel:
          {conversation.channel}
        </Text>
      </Grid>
      <Grid item sm={3} xs={6}>
        <Text bodySize="s" tagName="p" className="entityItem">
          <SSRIcon paths={documentIcon} className="entityIcon" />
          Messages:
          {conversation.messages.length}
        </Text>
      </Grid>
    </Grid>
  )
}

export default EntityBar
