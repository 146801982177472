import Button from "@ingka/button"
import Text from "@ingka/text"
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material"
import React from "react"
import UsageGraph from "./UsageGraph"
import { UserStats } from "./types"

const UserStatsDialog: React.FC<{
  open: boolean
  onClose: () => void
  userStats: UserStats | null
  loadingUserStats: boolean
}> = ({ open, onClose, userStats, loadingUserStats }) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>User Statistics</DialogTitle>
    <DialogContent>
      {userStats ? (
        <div style={{ marginRight: "3rem" }}>
          <div style={{ marginBottom: "1rem" }}>
            <Text tagName="h6">{userStats.email}</Text>
            <Text>Total Usage: {userStats.totalUsage}</Text>
            <Text>Total Cost: {userStats.totalCost}</Text>
          </div>
          <UsageGraph data={userStats.usageData} isLoading={loadingUserStats} />
        </div>
      ) : (
        <Text tagName="h6">No Usage found</Text>
      )}
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Close</Button>
    </DialogActions>
  </Dialog>
)

export default UserStatsDialog
