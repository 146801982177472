import { CircularProgress, FormControl, MenuItem, Select } from "@mui/material"
import { useAppDispatch, useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import { memo, useEffect, useState } from "react"
import { getAllPrompts } from "./firebaseUtils"
import SummaryDialog from "./SummaryDialog"
import { SavedPrompt } from "./types"
import { updateInsightsSummary } from "./utils"

const PrePromptedSummary = memo(({ labelId }: { labelId?: number }) => {
  const dispatch = useAppDispatch()
  const { insightsSummaryLoading, insights, insightsSummary } = useAppSelector(
    (state: RootState) => state.insights,
  )
  const [prePrompts, setPrePrompts] = useState<SavedPrompt[]>([])
  const [selectedPrompt, setSelectedPrompt] = useState<string>("")
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)

  useEffect(() => {
    const fetchPrompts = async () => {
      if (labelId) {
        const prompts = await getAllPrompts(`prompts/summarySystem/${labelId}`)
        setPrePrompts(prompts)
      }
    }

    fetchPrompts()
  }, [labelId])

  const handleChange = (prompt: SavedPrompt) => {
    const selectedName = prompt.displayName
    if (selectedName !== selectedPrompt && insights) {
      setSelectedPrompt(selectedName)
      updateInsightsSummary(prompt, insights, dispatch, labelId)
    }
    setDialogOpen(true)
  }

  const handleClose = () => {
    setDialogOpen(false)
  }

  const isDisabled =
    insightsSummaryLoading ||
    prePrompts.length === 0 ||
    Object.keys(insights).length === 0 ||
    !labelId

  return (
    <>
      <FormControl fullWidth>
        <Select
          disabled={isDisabled}
          labelId="summary-pre-prompt-select-label"
          value={selectedPrompt}
          displayEmpty
          style={{ height: "2rem" }}
          renderValue={(selected) => {
            if (insightsSummaryLoading) {
              return (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <CircularProgress size={24} />
                  <span style={{ marginLeft: "10px" }}>Loading...</span>
                </div>
              )
            }

            return selected || "Get summary"
          }}
        >
          {prePrompts.map((prompt) => (
            <MenuItem
              key={prompt.displayName}
              value={prompt.displayName}
              onClick={() => handleChange(prompt)}
            >
              {prompt.displayName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <SummaryDialog
        open={dialogOpen}
        onClose={handleClose}
        loading={insightsSummaryLoading}
        summary={insightsSummary}
      />
    </>
  )
})

export default PrePromptedSummary
