import Button from "@ingka/button"
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Menu,
  TextField,
} from "@mui/material"
import { IConversation, IConversationReviewMin } from "app/types"
import LabelTreeSelect from "features/listConversations/LabelTreeSelect"
import React from "react"
import { isNum } from "utils/utils"

interface ReviewMenuProps {
  anchorEl: null | HTMLElement
  onClose: () => void
  conversation: IConversation
  onSubmitReview: (review: IConversationReviewMin) => void
  onRemoveReview: () => void
}

const ReviewMenu: React.FC<ReviewMenuProps> = ({
  anchorEl,
  onClose,
  conversation,
  onSubmitReview,
  onRemoveReview,
}) => {
  const [correctLabelId, setCorrectLabelId] = React.useState<
    number | undefined
  >(conversation.review?.correctLabelId)
  const [comment, setComment] = React.useState<string | undefined | null>(
    conversation.review?.comment,
  )
  const handleCorrectLabelIdChange = (
    event: React.ChangeEvent<{ value: unknown }> | number,
    labelId?: number,
  ) => {
    if (isNum(event)) {
      setCorrectLabelId(event)
    } else if (correctLabelId !== labelId) {
      setCorrectLabelId(labelId)
    } else {
      setCorrectLabelId(undefined)
    }
  }

  const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComment(event.target.value)
  }

  const handleSubmit = () => {
    if (!correctLabelId) return
    onSubmitReview({ comment: comment ?? "", correctLabelId })
  }

  return (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={onClose}
    >
      <Grid sx={{ maxWidth: "25rem" }} container rowGap={1} margin={1}>
        {!!conversation.manualLabelId && (
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={conversation.manualLabelId === correctLabelId}
                  onChange={(e) =>
                    handleCorrectLabelIdChange(e, conversation.manualLabelId)
                  }
                  name="manualCorrect"
                  color="primary"
                />
              }
              label="Co-worker is correct"
            />
          </Grid>
        )}
        {!!conversation.autoLabelId && (
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={conversation.autoLabelId === correctLabelId}
                  onChange={(e) =>
                    handleCorrectLabelIdChange(e, conversation.autoLabelId)
                  }
                  name="autoCorrect"
                  color="primary"
                />
              }
              label="AI is correct"
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <LabelTreeSelect
            onChange={(newValue) => handleCorrectLabelIdChange(newValue[0])}
            label="Manually select label"
            value={correctLabelId ? [correctLabelId] : []}
            multiple={false}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Comment"
            multiline
            rows={4}
            value={comment}
            onChange={handleCommentChange}
            fullWidth
          />
        </Grid>
        <Grid
          item
          container
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "0.5rem",
          }}
        >
          <Button
            onClick={onRemoveReview}
            color="secondary"
            disabled={!correctLabelId || !conversation.review}
            type="tertiary"
          >
            Remove
          </Button>
          <Button
            type="emphasised"
            onClick={handleSubmit}
            disabled={
              !correctLabelId ||
              (comment === conversation.review?.comment &&
                correctLabelId === conversation.review?.correctLabelId)
            }
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </Menu>
  )
}

export default ReviewMenu
