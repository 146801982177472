import { Container, Grid, Skeleton, TextField } from "@mui/material"
import { useAppDispatch, useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import Progress from "components/Progress"
import { mapRange } from "utils/utils"
import { setPrompt } from "./insightsSlice"
import he from 'he'
import MarkdownPreview from '@uiw/react-markdown-preview';
import rehypeSanitize from "rehype-sanitize";

const rehypePlugins = [rehypeSanitize];

const InsightsSummary: React.FC = () => {
  const dispatch = useAppDispatch()
  const {
    promptsLoading,
    insightsSummary,
    insightsSummaryCost,
    insightsSummaryLoading,
    selectedSummaryPrompt,
  } = useAppSelector((state: RootState) => state.insights)

  return (
    <Grid container spacing={2} style={{marginBottom: "-2rem"}}>
      <Grid item xs={12} sm={6} style={{marginBottom: "2.5rem"}}>
        {promptsLoading ? (
          <Skeleton variant="rectangular" width="100%" height={266} />
        ) : (
          <TextField
            multiline
            fullWidth
            minRows={10}
            value={he.decode(selectedSummaryPrompt.prompt)}
            onChange={(e) =>
              dispatch(
                setPrompt({ 
                  prompt: he.encode(e.target.value), 
                  promptType: "summary" 
                }),
              )
            }
          />
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        {insightsSummaryLoading ? (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            height="16.5rem"
          >
            <Progress
              durationMs={
                Math.floor(Math.random() * 4000) +
                mapRange(selectedSummaryPrompt.amount, 20, 200, 10000, 25000)
              }
            />
          </Grid>
        ) : (
          <>
            {/* <TextField
              multiline
              fullWidth
              minRows={10}
              value={`Summary cost: ${insightsSummaryCost}\n\nSummary:\n${insightsSummary}`}
              InputProps={{ readOnly: true }}
              sx={{
                maxHeight: "16.5rem",
                overflowY: "auto",
                backgroundColor: "#fff",
              }}
            /> */}
            <Container sx={{ maxHeight: "16.5rem", overflowY: "scroll" }}>
              <MarkdownPreview source={insightsSummary} rehypePlugins={rehypePlugins} style={{marginLeft: "-1.75rem"}}/>
            </Container>
          </>
        )}
        {insightsSummary
          ? <p style={{color: "#777", fontSize: ".875rem", margin: "1.125rem 0 0 0"}}>Summary cost: {insightsSummaryCost} USD</p>
          : ""
        }
      </Grid>
    </Grid>
  )
}

export default InsightsSummary
