import React, { useEffect, useState } from "react"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  SelectChangeEvent,
} from "@mui/material"
import { useAppDispatch, useAppSelector } from "app/hooks"
import { fetchFlags, createFlagOnConversation } from "features/flag/flagSlice"

interface FlagDialogProps {
  open: boolean
  onClose: (addedFlagName?: string) => void
  conversationId: string
}

const FlagDialog: React.FC<FlagDialogProps> = ({
  open,
  onClose,
  conversationId,
}) => {
  const dispatch = useAppDispatch()
  const { flags, loading, saving } = useAppSelector((state) => state.flag)
  const [selectedFlag, setSelectedFlag] = useState("")

  useEffect(() => {
    if (open) {
      dispatch(fetchFlags())
    }
  }, [open, dispatch])

  const handleChange = (event: SelectChangeEvent<string>) => {
    setSelectedFlag(event.target.value)
  }

  const handleSave = () => {
    dispatch(
      createFlagOnConversation({ conversationId, flagName: selectedFlag }),
    )
    onClose(selectedFlag)
  }

  return (
    <Dialog open={open} onClose={() => onClose()}>
      <DialogTitle>Create Flag</DialogTitle>
      <DialogContent>
        {loading ? (
          <CircularProgress />
        ) : (
          <FormControl fullWidth>
            <InputLabel id="flag-select-label">Flag</InputLabel>
            <Select
              labelId="flag-select-label"
              value={selectedFlag}
              onChange={handleChange}
            >
              {flags?.map((flag) => (
                <MenuItem key={flag} value={flag}>
                  {flag}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>Cancel</Button>
        <Button onClick={handleSave} color="primary" disabled={saving}>
          {saving ? <CircularProgress size={24} /> : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default FlagDialog
