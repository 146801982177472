import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export interface ConversationSearchState {
  searchText: string
}

const initialState: ConversationSearchState = {
  searchText: "",
}

export const conversationSearchSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {
    setSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload
    },
  },
})

export const { setSearchText } = conversationSearchSlice.actions

export default conversationSearchSlice.reducer
