import { IConversation } from "app/types"
import { useCallback } from "react"

export const useCopyConversationLink = (
  setSnackBarOpen: (value: boolean) => void,
) => {
  const handleCopyConversationLink = useCallback(
    (conversation: IConversation) => {
      const currentUrl = window.location.href
      const url = new URL(currentUrl)

      const queryParams = {
        country: conversation.country,
        channel: conversation.channel,
        from_start_time: conversation.startTime,
        to_start_time: conversation.startTime,
        md5_conversation_id: conversation.id,
      }

      url.search = new URLSearchParams(queryParams).toString()

      navigator.clipboard.writeText(url.toString()).then(() => {
        setSnackBarOpen(true)
        setTimeout(() => {
          setSnackBarOpen(false)
        }, 3000)
      })
    },
    [setSnackBarOpen],
  )

  return { handleCopyConversationLink }
}
