import Button from "@ingka/button"
import SSRIcon from "@ingka/ssr-icon"
import docSum from "@ingka/ssr-icon/paths/document-magnifying-glass"
import Text from "@ingka/text"
import React from "react"

interface RenderConversationSummaryProps {
  summary: string
  isExpanded: boolean
  toggleExpand: () => void
}

const ConversationSummary: React.FC<RenderConversationSummaryProps> = ({
  summary,
  isExpanded,
  toggleExpand,
}) => {
  if (!summary) return null

  const truncatedSummary =
    summary.length > 200 ? `${summary.slice(0, 200)}...` : summary

  return (
    <Text
      style={{
        marginTop: 0,
        marginBottom: 0,
        marginLeft: "1rem",
        marginRight: "1rem",
        fontWeight: "400"
      }}
      tagName="h4"
    >
      <SSRIcon paths={docSum} className="entityIcon" />
      {isExpanded ? summary : truncatedSummary}
      {summary.length > 200 && (
        <Button
          className="verySmallBtn"
          small
          type="tertiary"
          style={{ marginLeft: "1rem" }}
          onClick={toggleExpand}
        >
          {isExpanded ? "  Show less" : "Show more"}
        </Button>
      )}
    </Text>
  )
}

export default ConversationSummary
