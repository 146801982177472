export interface IMessage {
  transcriptOrder: number
  userType: string
  language: string
  timestamp: string
  text: string | null
  textRaw: string | null
  isAutomated: boolean
  isAfterBillie: boolean
  isCustomer: boolean
}

export interface IConversation {
  id: string
  country: string
  channel: string
  messages: IMessage[]
  startTime: string
  endTime: string | null
  manualLabelId: number
  autoLabelId: number
  llmLabelId: number
  csatScore: number | null
  csatFeedback: Feedback
  entities: IGroupedEntity[]
  customerStatement: string
  review?: IConversationReviewMin
  flagNames?: string[]
}

export interface IConversationReviewMin {
  comment: string | null
  correctLabelId: number
}

interface Feedback {
  feedback: string
  translated: string
}

export interface IConversationReview {
  user_id: string
  published: string | null
  correct_label_id: number | null
  comment: string | null
  country: string
  conversation_id: string
  label_id: number | null
  model_id: string | null
}

export interface IGroupedEntity {
  entityName: string
  values: string[]
}

export type KeyValueType = {
  key: string
  value: string
}

export interface IConversationResponse {
  conversations: IConversation[]
  totalConversationAmount: number
}

export type market =
  | "AT"
  | "AU"
  | "BE"
  | "CA"
  | "CH"
  | "CN"
  | "CZ"
  | "DE"
  | "DK"
  | "ES"
  | "FI"
  | "FR"
  | "GB"
  | "HR"
  | "HU"
  | "IE"
  | "IN"
  | "IT"
  | "JP"
  | "KR"
  | "NL"
  | "NO"
  | "PL"
  | "PT"
  | "RO"
  | "RS"
  | "SE"
  | "SI"
  | "SK"
  | "US"

export const validMarkets: market[] = [
  "AT",
  "AU",
  "BE",
  "CA",
  "CH",
  "CN",
  "CZ",
  "DE",
  "DK",
  "ES",
  "FI",
  "FR",
  "GB",
  "HR",
  "HU",
  "IE",
  "IN",
  "IT",
  "JP",
  "KR",
  "NL",
  "NO",
  "PL",
  "PT",
  "RO",
  "RS",
  "SE",
  "SI",
  "SK",
  "US",
]

export const entityMarkets: market[] = ["CA", "GB", "SE", "US", "NO", "IE"]

export function isMarket(inputMarket: string): inputMarket is market {
  return validMarkets.includes(inputMarket as market)
}

export function isEntityMarket(inputMarket: string): inputMarket is market {
  return entityMarkets.includes(inputMarket as market)
}

export type Channel = "Chat" | "twitterdirect" | "facebooksession" | "Voice"

export const validChannels: Channel[] = [
  "Chat",
  "Voice",
  "twitterdirect",
  "facebooksession",
]
