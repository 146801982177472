import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { conversationInsights, insightsSummary } from "app/api"
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  setDoc,
} from "app/firebase"
import {
  LLMModel,
  InsightPromptRequest,
  InsightsState,
  PromptType,
  SavedPrompt,
  SummaryPromptRequest,
} from "./types"

// Async thunks
export const fetchConversationInsights = createAsyncThunk(
  "insights/fetchConversationInsights",
  async (req: InsightPromptRequest) => {
    const result = await conversationInsights(req)
    return result.data
  },
)

export const fetchInsightsSummary = createAsyncThunk(
  "insights/fetchInsightsSummary",
  async (req: SummaryPromptRequest) => {
    const result = await insightsSummary(req)
    return result?.data
  },
)

export const fetchSavedPrompts = createAsyncThunk(
  "insights/fetchSavedPrompts",
  async ({
    collectionIdentifier,
    systemPrompts,
  }: {
    collectionIdentifier: string
    systemPrompts?: boolean
  }) => {
    const [summarySnap, insightsSnap] = await Promise.all([
      getDocs(
        collection(
          "prompts",
          systemPrompts ? "summarySystem" : "summary",
          collectionIdentifier,
        ),
      ),
      getDocs(
        collection(
          "prompts",
          systemPrompts ? "insightSystem" : "insight",
          collectionIdentifier,
        ),
      ),
    ])

    const summaryPrompts: SavedPrompt[] = summarySnap.map(
      (doc) =>
        ({
          ...doc.data,
          id: doc.id,
        }) as SavedPrompt,
    )

    const insightsPrompts: SavedPrompt[] = insightsSnap.map(
      (doc) =>
        ({
          ...doc.data,
          id: doc.id,
        }) as SavedPrompt,
    )

    return { summaryPrompts, insightsPrompts }
  },
)

export const deleteSavedPrompt = createAsyncThunk(
  "insights/deleteSavedPrompt",
  async ({
    collectionIdentifier,
    promptType,
    promptId,
    systemPrompt,
  }: {
    collectionIdentifier: string
    promptType: PromptType
    promptId: string
    systemPrompt?: boolean
  }) => {
    await deleteDoc(
      doc(
        "prompts",
        systemPrompt ? `${promptType}System` : promptType,
        collectionIdentifier,
        promptId,
      ),
    )
    return { promptType, promptId }
  },
)

export const addSavedPrompt = createAsyncThunk(
  "insights/addSavedPrompt",
  async ({
    collectionIdentifier,
    promptType,
    prompt,
    systemPrompt,
  }: {
    collectionIdentifier: string
    promptType: PromptType
    prompt: SavedPrompt
    systemPrompt?: boolean
  }) => {
    const docRef = await addDoc(
      collection(
        "prompts",
        systemPrompt ? `${promptType}System` : promptType,
        collectionIdentifier,
      ),
      prompt,
    )

    if (!docRef) return

    return {
      promptType,
      savedPrompt: { ...prompt, id: docRef.id },
    }
  },
)

export const saveCurrentPrompt = createAsyncThunk(
  "insights/saveCurrentPrompt",
  async ({
    collectionIdentifier,
    prompt,
    promptType,
    systemPrompt,
  }: {
    collectionIdentifier: string
    prompt: SavedPrompt
    promptType: PromptType
    systemPrompt?: boolean
  }) => {
    await setDoc(
      doc(
        "prompts",
        systemPrompt ? `${promptType}System` : promptType,
        collectionIdentifier,
        prompt.id,
      ),
      prompt,
    )
    return { promptType, prompt }
  },
)

// Initial state
const initialState: InsightsState = {
  insights: {},
  insightsSummary: "",
  insightsSummaryCost: 0,
  insightsLoading: false,
  insightsSummaryLoading: false,
  summaryPromptIsSaved: true,
  insightPromptIsSaved: true,
  isSaving: false,
  promptsLoading: false,
  selectedSummaryPrompt: {
    displayName: "Prompt name",
    id: "initial-prompt",
    prompt: "Analyze the insights and return the main reasons",
    model: "GPT-4o",
    amount: 20,
  },
  selectedInsightPrompt: {
    displayName: "Prompt name",
    id: "initial-prompt",
    prompt:
      "Describe if customer wants IKEA to lower the price or apply a loyalty card/promotion retroactively - analyze the reason why customer requests the price adjustment on their orders - in less than 10 words ",
    model: "GPT-4o",
    amount: 20,
  }
}

// Slice
const insightsSlice = createSlice({
  name: "insights",
  initialState,
  reducers: {
    setModel: (
      state,
      action: PayloadAction<{ model: LLMModel; promptType: PromptType }>,
    ) => {
      const { model, promptType } = action.payload
      const selectedPrompt =
        promptType === "summary"
          ? state.selectedSummaryPrompt
          : state.selectedInsightPrompt
      selectedPrompt.model = model
      state[`${promptType}PromptIsSaved`] = false
    },
    setPrompt: (
      state,
      action: PayloadAction<{ prompt: string; promptType: PromptType }>,
    ) => {
      const { prompt, promptType } = action.payload
      const selectedPrompt =
        promptType === "summary"
          ? state.selectedSummaryPrompt
          : state.selectedInsightPrompt
      selectedPrompt.prompt = prompt
      state[`${promptType}PromptIsSaved`] = false
    },
    setTempPrompt: (
      state,
      action: PayloadAction<{ prompt: string; promptType: PromptType }>,
    ) => {
      const { prompt, promptType } = action.payload
      const selectedPrompt =
        promptType === "summary"
          ? state.selectedSummaryPrompt
          : state.selectedInsightPrompt
      selectedPrompt.prompt = prompt
      state[`${promptType}PromptIsSaved`] = false
    },
    setAmount: (
      state,
      action: PayloadAction<{ amount: number; promptType: PromptType }>,
    ) => {
      const { amount, promptType } = action.payload
      const selectedPrompt =
        promptType === "summary"
          ? state.selectedSummaryPrompt
          : state.selectedInsightPrompt
      selectedPrompt.amount = amount
      state[`${promptType}PromptIsSaved`] = false
    },
    setSelectedPrompt: (
      state,
      action: PayloadAction<{ prompt: SavedPrompt; promptType: PromptType }>,
    ) => {
      const { prompt, promptType } = action.payload

      const capitalPromptType = (promptType.charAt(0).toUpperCase() +
        promptType.slice(1)) as "Insight" | "Summary"

      state[`selected${capitalPromptType}Prompt`] = prompt
      state[`${promptType}PromptIsSaved`] = true
    },
    resetInsightsAndSummary: (state) => {
      state.insights = {}
      state.insightsSummary = ""
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchConversationInsights.pending, (state) => {
        state.insightsLoading = true
      })
      .addCase(fetchConversationInsights.fulfilled, (state, action) => {
        state.insightsLoading = false
        state.insights = action.payload ?? {}
      })
      .addCase(fetchConversationInsights.rejected, (state) => {
        state.insightsLoading = false
        state.insights = {}
      })
      .addCase(fetchInsightsSummary.pending, (state) => {
        state.insightsSummaryLoading = true
      })
      .addCase(fetchInsightsSummary.fulfilled, (state, action) => {
        state.insightsSummaryLoading = false
        state.insightsSummary = action.payload ? action.payload["analysis"] : ""
        state.insightsSummaryCost = action.payload
          ? action.payload["total_cost"]?.toFixed(6)
          : 0
      })
      .addCase(fetchInsightsSummary.rejected, (state) => {
        state.insightsSummaryLoading = false
      })
      .addCase(fetchSavedPrompts.pending, (state) => {
        state.promptsLoading = true
      })
      .addCase(fetchSavedPrompts.fulfilled, (state, action) => {
        const insightsPrompts = action.payload.insightsPrompts || []
        state.savedInsightsPrompts = insightsPrompts.map(
          (prompt) =>
            ({
              ...prompt,
              model: prompt.model ?? "GPT-4o",
              amount: prompt.amount ?? 20,
            }) as SavedPrompt,
        )
        const summaryPrompts = action.payload.summaryPrompts || []
        state.savedSummaryPrompts = summaryPrompts.map(
          (prompt) =>
            ({
              ...prompt,
              model: prompt.model ?? "GPT-4o",
              amount: prompt.amount ?? 20,
            }) as SavedPrompt,
        )
        state.selectedInsightPrompt =
          state.savedInsightsPrompts[0] || initialState.selectedInsightPrompt
        state.selectedSummaryPrompt =
          state.savedSummaryPrompts[0] || initialState.selectedSummaryPrompt
        state.promptsLoading = false
      })
      .addCase(fetchSavedPrompts.rejected, (state) => {
        state.savedInsightsPrompts = []
        state.savedSummaryPrompts = []
        state.selectedInsightPrompt = initialState.selectedInsightPrompt
        state.selectedSummaryPrompt = initialState.selectedSummaryPrompt
        state.promptsLoading = false
      })
      .addCase(addSavedPrompt.pending, (state) => {
        state.isSaving = true
      })
      .addCase(addSavedPrompt.fulfilled, (state, action) => {
        if (action.payload?.promptType === "insight") {
          state.savedInsightsPrompts = [
            ...(state.savedInsightsPrompts || []),
            action.payload.savedPrompt,
          ]
          state.selectedInsightPrompt = action.payload.savedPrompt
          state.insightPromptIsSaved = true
        } else if (action.payload?.promptType === "summary") {
          state.savedSummaryPrompts = [
            ...(state.savedSummaryPrompts || []),
            action.payload.savedPrompt,
          ]
          state.selectedSummaryPrompt = action.payload.savedPrompt
          state.summaryPromptIsSaved = true
        }
        state.isSaving = false
      })
      .addCase(addSavedPrompt.rejected, (state) => {
        state.isSaving = false
      })
      .addCase(deleteSavedPrompt.pending, (state) => {
        state.isSaving = true
      })
      .addCase(deleteSavedPrompt.fulfilled, (state, action) => {
        const { promptType, promptId } = action.payload
        if (promptType === "insight") {
          state.savedInsightsPrompts = (
            state.savedInsightsPrompts || []
          ).filter((p) => p.id !== promptId)
          if (state.selectedInsightPrompt.id === promptId) {
            state.selectedInsightPrompt =
              state.savedInsightsPrompts[0] ||
              initialState.selectedInsightPrompt
          }
        } else if (promptType === "summary") {
          state.savedSummaryPrompts = (state.savedSummaryPrompts || []).filter(
            (p) => p.id !== promptId,
          )
          if (state.selectedSummaryPrompt.id === promptId) {
            state.selectedSummaryPrompt =
              state.savedSummaryPrompts[0] || initialState.selectedSummaryPrompt
          }
        }
        state.isSaving = false
      })
      .addCase(deleteSavedPrompt.rejected, (state) => {
        state.isSaving = false
      })
      .addCase(saveCurrentPrompt.pending, (state) => {
        state.isSaving = true
      })
      .addCase(saveCurrentPrompt.fulfilled, (state, action) => {
        const { promptType, prompt } = action.payload
        if (promptType === "insight") {
          state.insightPromptIsSaved = true
          state.savedInsightsPrompts = (state.savedInsightsPrompts || []).map(
            (p) => (p.id === prompt.id ? prompt : p),
          )
        } else if (promptType === "summary") {
          state.summaryPromptIsSaved = true
          state.savedSummaryPrompts = (state.savedSummaryPrompts || []).map(
            (p) => (p.id === prompt.id ? prompt : p),
          )
        }
        state.isSaving = false
      })
      .addCase(saveCurrentPrompt.rejected, (state) => {
        state.isSaving = false
      })
  },
})

export const {
  setModel,
  setAmount,
  setPrompt,
  setSelectedPrompt,
  resetInsightsAndSummary,
} = insightsSlice.actions

export default insightsSlice.reducer
