import { ThemeProvider, createTheme } from "@mui/material/styles"
import "App.css"
import { auth } from "app/firebase"
import { useAppDispatch } from "app/hooks"
import { routes } from "app/routes"
import { checkIsAdmin, loginSuccess, logout } from "features/auth/authSlice"
import { IUser } from "features/auth/types"
import { useEffect } from "react"
import { BrowserRouter } from "react-router-dom"

const theme = createTheme({
  palette: {
    primary: {
      main: "#003E72",
      contrastText: "#ffdb00",
    },
  },
})

function App() {
  const dispatch = useAppDispatch()
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const mosaikUser: IUser = {
          displayName: user.displayName,
          email: user.email,
          id: user.uid,
        }

        dispatch(loginSuccess(mosaikUser))
        if (user.email) {
          dispatch(checkIsAdmin(user.email))
        }
      } else {
        dispatch(logout())
      }
    })

    return unsubscribe
  }, [dispatch])

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>{routes}</ThemeProvider>
    </BrowserRouter>
  )
}

export default App
