import Switch from "@ingka/switch"
import Toggle from "@ingka/toggle"

interface MessageOptionsProps {
  showOriginalText: boolean
  handleTranslationToggle: () => void
  handleAutomatedMessagesToggle: () => void
}

const MessageOptions: React.FC<MessageOptionsProps> = ({
  showOriginalText,
  handleTranslationToggle,
  handleAutomatedMessagesToggle,
}) => (
  <div style={{width: "100%", display: "flex", flexDirection: "row", height: "4rem", alignItems: "center", borderBottom: "1px solid #ccc", justifyContent: "space-between"}}>
    <Switch
      id="automatedMessageSwitch"
      label="Automated messages"
      value="automatedMessages"
      onChange={handleAutomatedMessagesToggle}
    />
    <Toggle
      iconOnly={false}
      buttons={[{ text: "English translation" }, { text: "Original language" }]}
      fluid={true}
      activeIndex={showOriginalText ? 1 : 0}
      onClick={handleTranslationToggle}
      style={{textWrap: "nowrap"}}
    />
  </div>
)

export default MessageOptions
