import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { IMessage } from "app/types"

export interface ListConversationState {
  messages: IMessage[]
}

const initialState: ListConversationState = {
  messages: [],
}

export const messagesSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {
    setMessages: (state, action: PayloadAction<IMessage[]>) => {
      state.messages = action.payload
    },
  },
})

export const { setMessages } = messagesSlice.actions

export default messagesSlice.reducer
