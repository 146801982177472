import { Channel } from "app/types"

export function mapRange(
  value: number,
  fromLow: number,
  fromHigh: number,
  toLow: number,
  toHigh: number,
): number {
  return ((value - fromLow) * (toHigh - toLow)) / (fromHigh - fromLow) + toLow
}

export const toChannel = (input: string): Channel => {
  const lowerCase = input.toLowerCase()
  switch (lowerCase) {
    case "chat":
    case "voice":
      return capitalizeFirstLetter(lowerCase) as Channel
    case "twitterdirect":
    case "facebooksession":
      return lowerCase
    default:
      throw new Error(`Not a valid channel value ${input}`)
  }
}

export const capitalizeFirstLetter = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}

export const isNum = (value: any): value is number => typeof value === "number"
