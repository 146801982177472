import { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import { setMessages } from "features/messages/messagesSlice"
import { setSelectedConversation } from "../listConversationSlice"

export const useSetInitialSelectedConversation = () => {
  const dispatch = useAppDispatch()
  const { loadingConversations, conversations, selectedConversation } =
    useAppSelector((state: RootState) => state.listConversation)

  useEffect(() => {
    const setInitialSelectedConversation = () => {
      if (
        !loadingConversations &&
        conversations?.length &&
        !selectedConversation
      ) {
        dispatch(setSelectedConversation(conversations[0]))
        dispatch(setMessages(conversations[0].messages))
      }
    }

    setInitialSelectedConversation()
  }, [dispatch, loadingConversations, conversations, selectedConversation])
}
