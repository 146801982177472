enum ModelCosts {
  gpt3In = 0.001,
  gpt3Out = 0.002,
  gtp4oIn = 0.005,
  gtp4oOut = 0.015,
  gpt4In = 0.01,
  gtp4Out = 0.03,
  geminiIn = 0.0035,
  geminiOut = 0.0105,
  gpt4ominiIn = 0.00015,
  gpt4ominiOut = 0.0006,
}

type ModelName = 'gtp4o' | 'gpt4omini';

function getModelCostKeys(modelName: ModelName): { inKey: keyof typeof ModelCosts; outKey: keyof typeof ModelCosts } {
  return {
    inKey: `${modelName}In` as keyof typeof ModelCosts,
    outKey: `${modelName}Out` as keyof typeof ModelCosts,
  };
}

const pricePreCalculator = (modelName: ModelName, promptTokens: number, completionTokens: number): number => {
  const { inKey, outKey } = getModelCostKeys(modelName);

  const inputCostPer1kTokens = ModelCosts[inKey];
  const outputCostPer1kTokens = ModelCosts[outKey];

  const inputCost = (promptTokens / 1000) * inputCostPer1kTokens;
  const outputCost = (completionTokens / 1000) * outputCostPer1kTokens;

  const totalCost = inputCost + outputCost;
  return parseFloat(totalCost.toFixed(6));
}

export const estimateCost = (modelName: ModelName, prompt: string, maxTokens: number): number => {
  const promptTokens = prompt.length;
  const estimatedCompletionTokens = maxTokens;

  const estimatedCost = pricePreCalculator(modelName, promptTokens, estimatedCompletionTokens);

  return estimatedCost;
}
