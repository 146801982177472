import {
  Grid,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material"
import { useAppDispatch, useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import Progress from "components/Progress"
import { mapRange } from "utils/utils"
import { setPrompt } from "./insightsSlice"
import he from 'he'

const InsightsTable: React.FC = () => {
  const dispatch = useAppDispatch()
  const { promptsLoading, insightsLoading, selectedInsightPrompt, insights } =
    useAppSelector((state: RootState) => state.insights)

    const nonEmptyInsightCount = Object.entries(insights)
    .filter(([, value]) => typeof value.insights === 'string' && value.insights.trim() !== '')
    .length;

  return (
    <Grid container spacing={2} style={{marginBottom: "-2rem"}}>
      <Grid item xs={12} sm={6} style={{marginBottom: "2.5rem"}}>
        {promptsLoading ? (
          <Skeleton variant="rectangular" width="100%" height={266} />
        ) : (
          <TextField
            multiline
            fullWidth
            minRows={10}
            value={he.decode(selectedInsightPrompt.prompt)}
            onChange={(e) =>
              dispatch(
                setPrompt({ 
                  prompt: he.encode(e.target.value), 
                  promptType: "insight" }),
              )
            }
          />
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        {insightsLoading ? (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            height="16.5rem"
          >
            <Progress
              durationMs={
                Math.floor(Math.random() * 4000) +
                mapRange(selectedInsightPrompt.amount, 20, 200, 9000, 20000)
              }
            />
          </Grid>
        ) : (
          <TableContainer sx={{ maxHeight: "16.5rem" }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Insight</TableCell>
                  <TableCell>Cost</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(insights).map(([key, value]) => (
                  <TableRow key={key}>
                    <TableCell>{value.insights}</TableCell>
                    <TableCell>{value.cost.toFixed(6)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {Object.entries(insights).length !== 0 
          ? <p style={{color: "#777", fontSize: ".875rem", margin: "1.25rem 0 0 0"}}>{nonEmptyInsightCount} insights returned. Total cost of running prompt: {Object.values(insights).reduce((sum, { cost }) => sum + cost, 0).toFixed(6)} USD</p>
          : ""
        }
      </Grid>
    </Grid>
  )
}

export default InsightsTable
