import { market } from "app/types"
import { EntityZipLocalStorageObject } from "./types"
import { getEntitiesZip } from "app/api"

export const getLocalStorageData = <T>(
  key: string,
): T | null => {
  const storedData = localStorage.getItem(key)
  return storedData ? JSON.parse(storedData) : null
}

export function setLocalStorageData(
  key: string,
  value: any,
) {
  localStorage.removeItem(key)
  localStorage.setItem(key, JSON.stringify(value))
}

export function isOlderThan7Days(timestamp: number): boolean {
  const sevenDaysInMs = 7 * 24 * 60 * 60 * 1000
  return isOlderThanTimestamp(timestamp, sevenDaysInMs)
}

function isOlderThanTimestamp(
  timestamp: number,
  amountOfMsOlder: number,
): boolean {
  const currentTimestamp = Date.now()
  const delta = currentTimestamp - timestamp
  return delta >= amountOfMsOlder
}

export const updateEntitiesLocalStorage = async (
  market: market,
): Promise<string> => {
  const newEntityZip = await getEntitiesZip(market)

  const newData: EntityZipLocalStorageObject = {
    entityZip: newEntityZip.data,
    timestamp: new Date().getTime(),
  }

  setLocalStorageData(
    `${market}_entities`,
    newData,
  )
  
  return newEntityZip.data
}
