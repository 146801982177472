import "@ingka/table/dist/style.css"
import { Grid } from "@mui/material"
import { useAppDispatch, useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import ConversationInsights from "features/insights/ConversationInsights"
import {
  addSavedPrompt,
  deleteSavedPrompt,
  fetchSavedPrompts,
  saveCurrentPrompt,
} from "features/insights/insightsSlice"
import { PromptType, SavedPrompt } from "features/insights/types"
import LabelTreeSelect from "features/listConversations/LabelTreeSelect"
import { useFetchLabels } from "features/listConversations/hooks/useFetchLabels"
import { useEffect, useState } from "react"

const SystemPromptEditor = () => {
  useFetchLabels()
  const dispatch = useAppDispatch()
  const [labelId, setLabelId] = useState<number | undefined>()
  const { savedInsightsPrompts, savedSummaryPrompts } = useAppSelector(
    (state: RootState) => state.insights,
  )

  useEffect(() => {
    if (labelId) {
      dispatch(
        fetchSavedPrompts({
          collectionIdentifier: labelId.toString(),
          systemPrompts: true,
        }),
      )
    }
  }, [dispatch, labelId])

  const handlePromptDelete = (promptId: string, promptType: PromptType) => {
    if (!labelId) return
    dispatch(
      deleteSavedPrompt({
        collectionIdentifier: labelId.toString(),
        promptType,
        promptId,
        systemPrompt: true,
      }),
    )
  }

  const handlePromptSave = (
    selectedPrompt: SavedPrompt,
    promptType: PromptType,
  ) => {
    if (!labelId) return
    dispatch(
      saveCurrentPrompt({
        collectionIdentifier: labelId.toString(),
        prompt: selectedPrompt,
        promptType,
        systemPrompt: true,
      }),
    )
  }

  const handleAddPrompt = (prompt: SavedPrompt, promptType: PromptType) => {
    if (!labelId) return
    dispatch(
      addSavedPrompt({
        prompt,
        collectionIdentifier: labelId.toString(),
        systemPrompt: true,
        promptType,
      }),
    )
  }

  return (
    <Grid container spacing={2} padding={2}>
      <Grid item xs={12}>
        <LabelTreeSelect
          onChange={(newValue) =>
            setLabelId(
              newValue && newValue.length === 1 ? newValue[0] : undefined,
            )
          }
          label="Label id"
          placeholder="Select a label"
          value={labelId ? [labelId] : []}
          multiple={false}
          disableSubLevels={true}
        />
      </Grid>
      {labelId && (
        <Grid item xs={12}>
          <ConversationInsights
            insightsPrompts={savedInsightsPrompts}
            summaryPrompts={savedSummaryPrompts}
            onAddPrompt={handleAddPrompt}
            onPromptSave={handlePromptSave}
            onPromptDelete={handlePromptDelete}
            editorMode={true}
          />
        </Grid>
      )}
    </Grid>
  )
}

export default SystemPromptEditor
