import { Backdrop, Box, LinearProgress, Typography } from "@mui/material"
import { useEffect, useState } from "react"

interface ProgressProps {
  durationMs: number
  useBackdrop?: boolean
}

const Progress: React.FC<ProgressProps> = ({
  durationMs = 5000,
  useBackdrop,
}) => {
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    const startTime = Date.now()
    const interval = setInterval(
      () => {
        const elapsedTime = Date.now() - startTime
        const elapsedPercentage = Math.min(elapsedTime / durationMs, 1)

        // Calculate progress with random and odd jumps
        const progressWithJumps = Math.min(
          elapsedPercentage + (Math.random() - 0.5) * 0.2,
          1,
        )

        // Ensure progress is always increasing
        setProgress((prevProgress) => {
          const newProgress = Math.floor(progressWithJumps * 100)
          return newProgress > prevProgress ? newProgress : prevProgress
        })

        if (elapsedPercentage >= 0.99) {
          clearInterval(interval)
        }
      },
      Math.floor(Math.random() * 300) + 100,
    ) // Random interval between 100-400ms

    return () => clearInterval(interval)
  }, [])

  const progressBar = (
    <Box sx={{ width: "80%", display: "flex", alignItems: "center" }}>
      <Box sx={{ flexGrow: 1, mr: 2 }}>
        <LinearProgress
          color="primary"
          variant="determinate"
          value={progress}
        />
      </Box>
      <Typography variant="body2" color="text.secondary">{`${Math.round(
        progress,
      )}%`}</Typography>
    </Box>
  )

  return useBackdrop ? (
    <Backdrop
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: "rgba(255, 255, 255, 0.5)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      open={true}
    >
      {progressBar}
    </Backdrop>
  ) : (
    <>{progressBar}</>
  )
}

export default Progress
