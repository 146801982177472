import Button from "@ingka/button"
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material"
import React from "react"

const DeleteUserDialog: React.FC<{
  open: boolean
  onClose: () => void
  selectedUserId: string | null
  onDeleteUser: () => void
  saving: boolean
}> = ({ open, onClose, selectedUserId, onDeleteUser, saving }) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>Confirm Delete</DialogTitle>
    <DialogContent>
      Are you sure you want to delete {selectedUserId}?
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} disabled={saving}>
        Cancel
      </Button>
      <Button
        type="emphasised"
        color="error"
        onClick={onDeleteUser}
        disabled={saving}
      >
        {saving ? <CircularProgress size={24} /> : "Delete"}
      </Button>
    </DialogActions>
  </Dialog>
)

export default DeleteUserDialog
