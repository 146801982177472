import Text from "@ingka/text"
import { Card, CardContent, CardMedia } from "@mui/material"
import mosaikLogo from "assets/MosaikLogo.svg"
import { ReactElement } from "react"

const InfoCard = ({
  header,
  text,
}: {
  header: string
  text: string | ReactElement
}) => {
  return (
    <Card
      sx={{
        backgroundColor: "#f4f4f4",
        width: "40rem",
        textAlign: "center",
      }}
    >
      <CardMedia
        sx={{ height: 150, width: "100%" }}
        image={mosaikLogo}
        title="Tagging"
      />
      <CardContent>
        <Text tagName="h5">{header}</Text>
        <Text tagName="p" color="text.secondary">
          {text}
        </Text>
      </CardContent>
    </Card>
  )
}

export default InfoCard
