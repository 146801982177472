import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  setDoc,
  updateDoc,
} from "app/firebase"
import { AdminInitState, AdminUser } from "./types"
import { LLMModel } from "features/insights/types"

export const getAdminUsers = createAsyncThunk("admin/getUsers", async () => {
  const adminRef = collection("config", "admin", "users")
  const snap = await getDocs(adminRef)

  const adminUsers: AdminUser[] = snap.map((doc) => ({
    email: doc.id,
    description: doc.data?.description,
  }))

  return adminUsers
})

export const savePromptSettings = createAsyncThunk(
  "admin/savePromptSettings",
  async ({
    labelId,
    insightPromptId,
    summaryPromptId,
    insightSettings,
    summarySettings,
  }: {
    labelId: string
    insightPromptId: string
    summaryPromptId: string
    insightSettings: { conversationAmount: number; llmVersion: LLMModel }
    summarySettings: { conversationAmount: number; llmVersion: LLMModel }
  }) => {
    try {
      const summaryRef = doc(
        "prompts",
        "summarySystem",
        labelId,
        summaryPromptId,
      )
      const insightsRef = doc(
        "prompts",
        "insightSystem",
        labelId,
        insightPromptId,
      )

      await Promise.all([
        updateDoc(summaryRef, summarySettings),
        updateDoc(insightsRef, insightSettings),
      ])
    } catch (error) {
      console.error("Error updating document: ", error)
      throw error
    }
  },
)

export const addAdminUser = createAsyncThunk(
  "admin/addUser",
  async (newAdmin: AdminUser) => {
    const docRef = doc("config", "admin", "users", newAdmin.email)
    await setDoc(docRef, {
      description: newAdmin.description,
    })
    return newAdmin
  },
)

export const deleteAdminUser = createAsyncThunk(
  "admin/deleteUser",
  async (email: string) => {
    const collectionRef = doc("config", "admin", "users", email)
    await deleteDoc(collectionRef)
    return email
  },
)

const initialState: AdminInitState = {
  loading: false,
  saving: false,
}

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAdminUsers.pending, (state) => {
        state.loading = true
      })
      .addCase(
        getAdminUsers.fulfilled,
        (state, action: PayloadAction<AdminUser[]>) => {
          state.loading = false
          state.adminUsers = action.payload
        },
      )
      .addCase(getAdminUsers.rejected, (state) => {
        state.loading = false
        state.adminUsers = []
      })
      .addCase(addAdminUser.pending, (state) => {
        state.saving = true
      })
      .addCase(
        addAdminUser.fulfilled,
        (state, action: PayloadAction<AdminUser>) => {
          state.saving = false
          state.adminUsers = [...(state.adminUsers ?? []), action.payload]
        },
      )
      .addCase(addAdminUser.rejected, (state) => {
        state.saving = false
      })
      .addCase(deleteAdminUser.pending, (state) => {
        state.saving = true
      })
      .addCase(
        deleteAdminUser.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.saving = false
          state.adminUsers = state.adminUsers?.filter(
            (u) => u.email !== action.payload,
          )
        },
      )
      .addCase(deleteAdminUser.rejected, (state) => {
        state.saving = false
      })
      .addCase(savePromptSettings.pending, (state) => {
        state.saving = true
      })
      .addCase(savePromptSettings.fulfilled, (state) => {
        state.saving = false
      })
      .addCase(savePromptSettings.rejected, (state) => {
        state.saving = false
      })
  },
})

export default adminSlice.reducer
