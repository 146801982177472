import React, { useState } from "react"
import {
  Accordion,
  AccordionSummary,
  Grid,
  IconButton,
  Skeleton,
  Snackbar,
  Tooltip,
} from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import Button from "@ingka/button"
import SSRIcon from "@ingka/ssr-icon"
import closeIcon from "@ingka/ssr-icon/paths/cross"
import flagIcon from "@ingka/ssr-icon/paths/flag"
import idIcon from "@ingka/ssr-icon/paths/id"
import shareIcon from "@ingka/ssr-icon/paths/share-network"
import Text from "@ingka/text"
import { useAppDispatch, useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import { IConversation } from "app/types"
import { ConversationInsight } from "features/insights/types"
import { setMessages } from "features/messages/messagesSlice"
import ReviewButton from "features/review/ReviewButton"
import ConversationSummary from "./ConversationSummary"
import RenderEntities from "./EntitiesAccordion"
import EntityBar from "./EntityBar"
import GetLabelBanner from "./LabelBanner"
import { useCopyConversationLink } from "./hooks/useCopyConversationLink"
import { setSelectedConversation, updateFlagNames } from "./listConversationSlice"
import FlagDialog from "features/flag/FlagDialog"

interface ConversationItemProps {
  conversation: IConversation
  showManualTagId: boolean
  insight?: ConversationInsight
}

const ConversationItem: React.FC<ConversationItemProps> = ({
  conversation,
  showManualTagId,
  insight,
}) => {
  const dispatch = useAppDispatch()
  const { selectedConversation, loadingLabels } = useAppSelector(
    (state: RootState) => state.listConversation,
  )
  const [isExpanded, setIsExpanded] = useState(false)
  const [snackBarOpen, setSnackBarOpen] = useState(false)
  const [flagDialogOpen, setFlagDialogOpen] = useState(false)

  const { handleCopyConversationLink } =
    useCopyConversationLink(setSnackBarOpen)

  const toggleExpand = () => {
    setIsExpanded((prevState) => !prevState)
  }

  const handleConversationClick = (conversation: IConversation) => {
    if (conversation.id !== selectedConversation?.id) {
      dispatch(setSelectedConversation(conversation))
      dispatch(setMessages(conversation.messages))
    }
  }

  const onFlagDialogClose = (addedFlagName?: string): void => {
    setFlagDialogOpen(false)
    if(addedFlagName) {
      const flagNames = conversation.flagNames ? [addedFlagName, ...conversation.flagNames] : [addedFlagName]
      dispatch(updateFlagNames({ conversationId: conversation.id, flagNames }))
    }
  }

  const hasEntities =
    !!conversation.entities?.length || !!conversation.csatFeedback?.feedback

  return (
    <Grid
      className="conversationListItem"
      id="conversationListItem"
      container
      rowGap={1}
      sx={{
        backgroundColor: "#fff !important",
        border: 
        selectedConversation?.id === conversation.id
            ? "1px solid #999 !important"
            : "1px solid #f0f0f0",
        padding: ".5rem"
      }}
      onClick={() => handleConversationClick(conversation)}
    >
      <Grid container item xs={12}>
        <Grid item xs={9}>
          {loadingLabels ? (
            <Skeleton
              variant="rounded"
              width={"100%"}
              height={30}
              style={{ marginBottom: "1rem" }}
            />
          ) : (
            <GetLabelBanner
              conversation={conversation}
              showManualTagId={showManualTagId}
            />
          )}
        </Grid>
        <Grid item xs={3} justifyContent="flex-end" display="flex">
          <ReviewButton conversation={conversation} />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {!insight ? (
          <ConversationSummary
            summary={conversation.customerStatement}
            isExpanded={isExpanded}
            toggleExpand={toggleExpand}
          />
        ) : (
          <ConversationSummary
            summary={insight?.insights}
            isExpanded={isExpanded}
            toggleExpand={toggleExpand}
          />
        )}
      </Grid>
      <EntityBar conversation={conversation} />
      <Grid item xs={12}>
        <Accordion
          style={{ backgroundColor: "#f5f5f5" }}
          disabled={!hasEntities}
        >
          <AccordionSummary
            aria-controls="panel3d-content"
            id="panel3d-header"
            expandIcon={<ExpandMoreIcon />}
          >
            <Text>
              {hasEntities ? "Show additional info" : "No additional info"}
            </Text>
          </AccordionSummary>
          <RenderEntities conversation={conversation} />
        </Accordion>
      </Grid>
      <Grid item container xs={12} justifyContent="space-between">
        <Grid item xs={6}>
          <Text style={{ display: "flex", alignItems: "center" }}>
            <SSRIcon paths={idIcon} className="entityIcon" />
            {conversation.id}
          </Text>
        </Grid>

        <Tooltip
            title={
              conversation.flagNames && conversation.flagNames.length > 0
                ? conversation.flagNames.join(", ")
                : "No flags"
            }
          >
            <Button
              type="tertiary"
              small
              onClick={() => setFlagDialogOpen(true)}
              ssrIcon={flagIcon}
            >
              {conversation.flagNames && conversation.flagNames.length > 0
                ? conversation.flagNames[0]
                : ""}
            </Button>
          </Tooltip>
          <Button
            type="tertiary"
            small
            iconPosition="leading"
            ssrIcon={shareIcon}
            onClick={() => handleCopyConversationLink(conversation)}
          >
            Share Conversation
          </Button>
      </Grid>
      <Snackbar
        open={snackBarOpen}
        autoHideDuration={3000}
        message="Conversation link copied to clipboard!"
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => setSnackBarOpen(false)}
          >
            <SSRIcon paths={closeIcon} className="entityIcon" />
          </IconButton>
        }
      />
      <FlagDialog
        open={flagDialogOpen}
        onClose={(addedFlagName) => onFlagDialogClose(addedFlagName)}
        conversationId={conversation.id}
      />
    </Grid>
  )
}

export default ConversationItem
