import Button from "@ingka/button"
import arrowDown from "@ingka/ssr-icon/paths/arrow-down"
import arrowRight from "@ingka/ssr-icon/paths/arrow-right"
import filterIcon from "@ingka/ssr-icon/paths/filters"
import { Box, Collapse, Drawer, MenuItem, Skeleton } from "@mui/material"
import ListItem from "@mui/material/ListItem"
import TextField from "@mui/material/TextField"
import { useAppDispatch, useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import {
  Channel,
  KeyValueType,
  market,
  validChannels,
  validMarkets,
} from "app/types"
import * as React from "react"
import { useLocation } from "react-router-dom"
import { toChannel } from "utils/utils"
import LabelTreeSelect from "./LabelTreeSelect"
import {
  fetchConversations,
  loadEntitiesZipAsync,
  setFilters,
} from "./listConversationSlice"
import JSZip from "jszip"
import { entityRow, EntityZipLocalStorageObject } from "./types"

import { isEntityMarket } from "./../../app/types"

interface FilterMap {
  from_start_time: Date
  to_start_time: Date
  channel: Channel[]
  country: market[]
  manual_tag_label_id: number[]
  prediction_label_id: number[]
  llm_label_id: number[]
  not_llm_label_id: number[]
  llm_prompt_hash: string
  text_contains: ""
  product: string
  touchpoint: string
  hfb: string
  unit_name: string
  unit_code: string
  service_provider: string
  service: string
  cust_sat: string
  flag_name: string
}

const currentDate = new Date()
const oneMonthAgo: Date = new Date(
  currentDate.getFullYear(),
  currentDate.getMonth() - 1,
  currentDate.getDate(),
)

const initFilters: FilterMap = {
  from_start_time: oneMonthAgo,
  to_start_time: currentDate,
  channel: [],
  country: [],
  manual_tag_label_id: [],
  prediction_label_id: [],
  llm_label_id: [],
  not_llm_label_id: [],
  llm_prompt_hash: "",
  text_contains: "",
  product: "",
  touchpoint: "",
  hfb: "",
  unit_name: "",
  unit_code: "",
  service_provider: "",
  service: "",
  cust_sat: "",
  flag_name: "",
}

const ConversationFilterMenu = ({
  text,
  fluid,
  small,
  style,
}: {
  text?: string
  fluid?: boolean
  small?: boolean
  style?: React.CSSProperties
}) => {
  const dispatch = useAppDispatch()
  const { filters, entitiesLoading } = useAppSelector(
    (state: RootState) => state.listConversation,
  )
  const { flags, loading: flagsLoading } = useAppSelector(
    (state: RootState) => state.flag,
  )
  const { isAdmin } = useAppSelector((state: RootState) => state.auth)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const [menuFilters, setMenuFilters] = React.useState<FilterMap>(initFilters)
  const [expandedSubcategory, setExpandedSubcategory] = React.useState(false)
  const [expandedAdminFilters, setExpandedAdminFilters] = React.useState(false)
  const location = useLocation()

  const [entityTable, setEntityTable] = React.useState<entityRow[]>([])

  React.useEffect(() => {
    if (menuFilters.country[0]) {
      console.log("entity filters dispatched", menuFilters.country[0])
      dispatch(loadEntitiesZipAsync({ inputMarket: menuFilters.country[0] }))
    }
  }, [menuFilters.country, dispatch])

  React.useEffect(() => {
    const loadEntities = async () => {
      try {
        const zipFileKey = `${menuFilters.country[0]}_entities`
        const zipFileData = localStorage.getItem(zipFileKey)

        if (zipFileData) {
          const parsedData: EntityZipLocalStorageObject =
            JSON.parse(zipFileData)
          const binaryString = atob(parsedData.entityZip)
          const byteArray = Uint8Array.from(binaryString, (char) =>
            char.charCodeAt(0),
          )

          const zip = new JSZip()
          const unzipped = await zip.loadAsync(byteArray)
          const uint8Array = await unzipped.generateAsync({
            type: "uint8array",
          })

          const decoder = new TextDecoder("utf-8")
          let fileContent = decoder.decode(uint8Array)

          const firstBracketIndex = fileContent.indexOf("[{")
          if (firstBracketIndex !== -1) {
            fileContent = fileContent.substring(firstBracketIndex)

            const lastBracketIndex = fileContent.lastIndexOf("}]")
            if (lastBracketIndex !== -1) {
              fileContent = fileContent.substring(0, lastBracketIndex + 2)
            }
          }

          const entityData: entityRow[] = JSON.parse(fileContent)
          setEntityTable(entityData)
        }
      } catch (error) {
        console.error("Error fetching entities:", error)
      }
    }

    if (menuFilters.country.length === 1 && isEntityMarket(menuFilters.country[0])) {
      loadEntities()
    }
  }, [menuFilters.country, entitiesLoading])

  React.useEffect(() => {
    if (filters.length > 0) {
      const currentFilters: FilterMap = { ...menuFilters }
      for (const filter of filters) {
        const filterKey = filter.key as keyof FilterMap
        let value: any[] | string = filter.value
        if (filterKey === "country") {
          value = filter.value
            .split(",")
            .map((c) => c.toUpperCase()) as market[]
        } else if (filterKey === "channel") {
          value = filter.value.split(",").map((c) => toChannel(c))
        } else if (
          filterKey === "manual_tag_label_id" ||
          filterKey === "prediction_label_id" ||
          filterKey === "llm_label_id" ||
          filterKey === "not_llm_label_id"
        ) {
          value = filter.value.split(",").map((c) => Number(c))
        }
        currentFilters[filterKey] = value as any
      }
      setMenuFilters(currentFilters)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, dispatch])

  const updateQueryParams = (params: KeyValueType[]) => {
    const searchParams = new URLSearchParams()
    params.forEach(({ key, value }) => {
      searchParams.append(key, value)
    })

    const newSearch = searchParams.toString()
    const newUrl = `${location.pathname}?${newSearch}`

    window.history.replaceState({}, "", newUrl)
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleFilterChange = (
    event: React.ChangeEvent<HTMLInputElement> | any,
    valueName?: string,
  ) => {
    const columnName = event.target?.name ?? valueName
    const filterValue = event.target?.value ?? event

    setMenuFilters((prevFilters) => ({
      ...prevFilters,
      [columnName]: filterValue,
    }))
  }

  const handleArrayFilterChange = (event: any) => {
    const value = event.target.value
    const name = event.target.name as keyof FilterMap
    setMenuFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }))
  }

  const handleApplyFilters = () => {
    handleClose()
    let kvFilters: KeyValueType[] = []
    for (const [key, value] of Object.entries(menuFilters)) {
      if (value && value.length > 0) {
        kvFilters.push({ key, value: value.toString() })
      }
    }
    dispatch(fetchConversations(kvFilters))
    dispatch(setFilters(kvFilters))
    updateQueryParams(kvFilters)
  }

  const handleClearFilters = () => {
    setMenuFilters({ ...initFilters })
  }

  return (
    <>
      <Button
        style={style}
        ssrIcon={filterIcon}
        onClick={(e: any) => handleClick(e)}
        type="tertiary"
        fluid={fluid}
        iconOnly={!!text}
        small={small}
      >
        {text}
      </Button>
      <Drawer
        sx={{ width: "30rem" }}
        id="filter-drawer"
        anchor="left"
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: "30rem",
          },
        }}
      >
        <Box
          sx={{
            padding: '2rem .5rem',
          }}
        >
        <ListItem>
          <TextField
            fullWidth
            label="From Start Time"
            type="date"
            name="from_start_time"
            value={menuFilters.from_start_time}
            onChange={handleFilterChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </ListItem>
        <ListItem>
          <TextField
            fullWidth
            label="To Start Time"
            type="date"
            name="to_start_time"
            value={menuFilters.to_start_time}
            onChange={handleFilterChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </ListItem>
        <ListItem>
          <TextField
            fullWidth
            label="Channel"
            select
            name="channel"
            value={menuFilters.channel}
            onChange={(event) => handleArrayFilterChange(event)}
            SelectProps={{
              multiple: true,
            }}
          >
            {validChannels.map((channel) => (
              <MenuItem value={channel} key={channel}>
                {channel}
              </MenuItem>
            ))}
          </TextField>
        </ListItem>
        <ListItem>
          <TextField
            fullWidth
            label="Country"
            select
            name="country"
            value={menuFilters.country}
            onChange={(event) => {
              menuFilters.service = "";
              menuFilters.service_provider = "";
              menuFilters.unit_name = "";
              menuFilters.unit_code = "";
              handleArrayFilterChange(event)
            }}
            SelectProps={{
              multiple: true,
              MenuProps: {
                sx: { height: "300px" },
              },
            }}
          >
            {validMarkets.map((country) => (
              <MenuItem value={country} key={country}>
                {country}
              </MenuItem>
            ))}
          </TextField>
        </ListItem>
        <ListItem>
          <LabelTreeSelect
            onChange={(newValue) =>
              handleFilterChange(newValue, "manual_tag_label_id")
            }
            label="Manual Label Id"
            value={menuFilters.manual_tag_label_id}
            multiple={true}
          />
        </ListItem>
        <ListItem>
          <LabelTreeSelect
            onChange={(newValue) =>
              handleFilterChange(newValue, "prediction_label_id")
            }
            label="Auto Label Id"
            value={menuFilters.prediction_label_id}
            multiple={true}
          />
        </ListItem>
        <ListItem>
          <TextField
            fullWidth
            label="CSAT Score"
            name="cust_sat"
            value={menuFilters.cust_sat}
            onChange={handleFilterChange}
          />
        </ListItem>
        <ListItem>
          {flagsLoading ? (
            <Skeleton width="100%" />
          ) : (
            <TextField
              fullWidth
              label="Flag"
              select
              name="flag_name"
              value={menuFilters.flag_name}
              onChange={handleFilterChange}
              disabled={flagsLoading}
              SelectProps={{
                multiple: false,
              }}
            >
              <MenuItem value="">None</MenuItem>
              {flags?.map((flag) => (
                <MenuItem value={flag} key={flag}>
                  {flag}
                </MenuItem>
              ))}
            </TextField>
          )}
        </ListItem>
        {isAdmin && (
          <div>
            <ListItem>
              <Button
                ssrIcon={expandedSubcategory ? arrowDown : arrowRight}
                onClick={() => setExpandedSubcategory(!expandedSubcategory)}
                type="tertiary"
                fluid
              >
                {expandedSubcategory ? "Collapse" : "Expand"} Entities
              </Button>
            </ListItem>
            <Collapse in={expandedSubcategory}>
              {menuFilters.country.length === 1 && isEntityMarket(menuFilters.country[0]) ? (
                entitiesLoading ? (
                  <Skeleton width="100%" />
                ) : (
                  <>
                    <div style={{ opacity: ".5", pointerEvents: "none" }}>
                      <ListItem>
                        <TextField
                          select
                          fullWidth
                          label="Product"
                          name="product"
                          value={menuFilters.product || ""}
                          onChange={handleFilterChange}
                        >
                          {entityTable.map((entity) => {
                              const productName = entity.product_name
                              return (
                                productName && (
                                  <MenuItem
                                    key={productName}
                                    value={productName}
                                  >
                                    {productName}
                                  </MenuItem>
                                )
                              )
                            })
                          }
                        </TextField>
                      </ListItem>
                      <ListItem>
                        <TextField
                          fullWidth
                          label="Touchpoint"
                          name="touchpoint"
                          value={menuFilters.touchpoint}
                          onChange={handleFilterChange}
                        />
                      </ListItem>
                      <ListItem>
                        <TextField
                          fullWidth
                          label="HFB"
                          name="hfb"
                          value={menuFilters.hfb}
                          onChange={handleFilterChange}
                        />
                      </ListItem>
                    </div>
                    <ListItem>
                      <TextField
                        fullWidth
                        label={`Unit name`}
                        select
                        name="unit_name"
                        value={menuFilters.unit_name}
                        onChange={handleFilterChange}
                      >
                        <MenuItem value="">None</MenuItem>
                        {entityTable.map((entity) => {
                            const alternative = entity.unit_name
                            return (
                              alternative && (
                                <MenuItem key={alternative} value={alternative}>
                                  {alternative}
                                </MenuItem>
                              )
                            )
                          })
                        }
                      </TextField>
                    </ListItem>
                    <ListItem>
                      <TextField
                        fullWidth
                        select
                        label={`Unit code`}
                        name="unit_code"
                        value={menuFilters.unit_code}
                        onChange={handleFilterChange}
                      >
                        <MenuItem value="">None</MenuItem>
                          {entityTable.map((entity) => {
                            const alternative = entity.unit_code?.toString()
                            return (
                              alternative && (
                                <MenuItem key={alternative} value={alternative}>
                                  {alternative}
                                </MenuItem>
                              )
                            )
                          })
                        }
                      </TextField>
                    </ListItem>
                    <ListItem>
                      <TextField
                        fullWidth
                        select
                        label={`Service provider`}
                        name="service_provider"
                        value={menuFilters.service_provider}
                        onChange={handleFilterChange}
                      >
                        <MenuItem value="">None</MenuItem>
                        {entityTable.map((entity) => {
                            const alternative = entity.service_provider_name
                            return (
                              alternative && (
                                <MenuItem key={alternative} value={alternative}>
                                  {alternative}
                                </MenuItem>
                              )
                            )
                          })
                        }
                      </TextField>
                    </ListItem>
                    <ListItem>
                      <TextField
                        fullWidth
                        select
                        label={`Service`}
                        name="service"
                        value={menuFilters.service}
                        onChange={handleFilterChange}
                      >
                        <MenuItem value="">None</MenuItem>
                        {entityTable.map((entity) => {
                            const alternative = entity.service_entity_name
                            return (
                              alternative && (
                                <MenuItem key={alternative} value={alternative}>
                                  {alternative}
                                </MenuItem>
                              )
                            )
                          })
                        }
                      </TextField>
                    </ListItem>
                  </>
                )
              ) : (
                <ListItem style={{border: "1px solid #eee", borderRadius: ".25rem", margin: "0 1rem", width: "calc(100% - 2rem", padding: "2rem", textAlign: "center"}}>
                  <p>
                    {menuFilters.country.length === 1 && !isEntityMarket(menuFilters.country[0]) ? "Sorry, the selected country does not have entity filtering yet." : ""}
                    {menuFilters.country.length === 0 ? "Please select a country to use the entity filters." : ""}
                    {menuFilters.country.length > 1 ? "Please only have one country selected to use the entity filters." : ""}
                  </p>
                </ListItem>
              )}
            </Collapse>
            <ListItem>
              <Button
                ssrIcon={expandedAdminFilters ? arrowDown : arrowRight}
                onClick={() => setExpandedAdminFilters(!expandedAdminFilters)}
                type="tertiary"
                fluid
              >
                {expandedAdminFilters ? "Collapse" : "Expand"} Admin filters
              </Button>
            </ListItem>
            <Collapse in={expandedAdminFilters}>
              <ListItem>
                <TextField
                  fullWidth
                  label="LLM Prompt Hash"
                  name="llm_prompt_hash"
                  value={menuFilters.llm_prompt_hash}
                  onChange={handleFilterChange}
                />
              </ListItem>
              <ListItem>
                <LabelTreeSelect
                  onChange={(newValue) =>
                    handleFilterChange(newValue, "llm_label_id")
                  }
                  label="LLM Label Id"
                  value={menuFilters.llm_label_id}
                  multiple={true}
                />
              </ListItem>
              <ListItem>
                <LabelTreeSelect
                  onChange={(newValue) =>
                    handleFilterChange(newValue, "not_llm_label_id")
                  }
                  label="Not LLM Label Id"
                  value={menuFilters.not_llm_label_id}
                  multiple={true}
                />
              </ListItem>
            </Collapse>
          </div>
        )}
        <ListItem sx={{ display: "flex", justifyContent: "space-between" }}>
          <Button type="primary" onClick={handleApplyFilters}>
            Apply Filters
          </Button>
          <Button type="secondary" onClick={handleClearFilters}>
            Clear Filters
          </Button>
        </ListItem>
        </Box>
      </Drawer>
    </>
  )
}

export default ConversationFilterMenu
