import { CircularProgress, FormControl, MenuItem, Select } from "@mui/material"
import { useAppDispatch, useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import { memo, useEffect, useState } from "react"
import { getAllPrompts } from "./firebaseUtils"
import { SavedPrompt } from "./types"
import { updateInsights } from "./utils"

const PrePromptedInsights = memo(({ labelId }: { labelId?: number }) => {
  const dispatch = useAppDispatch()
  const { insightsLoading } = useAppSelector(
    (state: RootState) => state.insights,
  )
  const { conversations } = useAppSelector(
    (state: RootState) => state.listConversation,
  )
  const [prePrompts, setPrePrompts] = useState<SavedPrompt[]>([])
  const [selectedPrompt, setSelectedPrompt] = useState<string>("")

  useEffect(() => {
    const fetchPrompts = async () => {
      if (labelId) {
        const prompts = await getAllPrompts(`prompts/insightSystem/${labelId}`)
        setPrePrompts(prompts)
      }
    }

    fetchPrompts()
  }, [labelId])

  const handleChange = (prompt: SavedPrompt) => {
    const selectedName = prompt.displayName
    if (selectedName !== selectedPrompt && conversations) {
      setSelectedPrompt(selectedName)
      updateInsights(prompt, conversations, dispatch, labelId)
    }
  }

  const isDisabled =
    insightsLoading ||
    prePrompts.length === 0 ||
    (conversations && conversations.length === 0) ||
    !labelId

  return (
    <FormControl fullWidth>
      <Select
        disabled={isDisabled}
        labelId="pre-prompt-select-label"
        value={selectedPrompt}
        displayEmpty
        style={{ height: "2rem" }}
        renderValue={(selected) => {
          if (insightsLoading) {
            return (
              <div style={{ display: "flex", alignItems: "center" }}>
                <CircularProgress size={24} />
                <span style={{ marginLeft: "10px" }}>Loading...</span>
              </div>
            )
          }

          return selected || "Get insights"
        }}
      >
        {prePrompts.map((prompt) => (
          <MenuItem
            key={prompt.displayName}
            value={prompt.displayName}
            onClick={() => handleChange(prompt)}
          >
            {prompt.displayName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
})

export default PrePromptedInsights
