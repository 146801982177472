import Grid from "@mui/material/Grid"
import Text from "@ingka/text"
import { IMessage } from "app/types"

interface MessageItemProps {
  message: IMessage
  index: number
  showOriginalText: boolean
  hideAutomatedMessages: boolean
}

const MessageItem: React.FC<MessageItemProps> = ({
  message,
  index,
  showOriginalText,
  hideAutomatedMessages,
}) => {
  if (message.isAutomated && hideAutomatedMessages) {
    return null
  }

  return (
    <Grid columnSpacing={1} item container xs={12}>
      <Grid item xs={1} className="centerContent">
        <Text tagName="p">{index}</Text>
      </Grid>
      <Grid
        item
        xs={8}
        className={
          message.userType === "agent"
            ? "messageBox messageBoxAgent"
            : "messageBox messageBoxCustomer"
        }
      >
        <Text tagName="p">
          {showOriginalText ? message.textRaw : message.text}
        </Text>
      </Grid>
      <Grid
        item
        xs={2}
        className="centerContent"
        sx={{
          wordWrap: "break-word",
          overflowWrap: "break-word",
          wordBreak: "break-all",
        }}
      >
        <Text tagName="p">{message.userType}</Text>
      </Grid>
    </Grid>
  )
}

export default MessageItem
