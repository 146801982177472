import { useEffect } from "react"
import { useSearchParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import { KeyValueType } from "app/types"
import { purify } from "utils/sanitize"
import { fetchConversations, setFilters } from "../listConversationSlice"

export const useFetchConversations = () => {
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const { loadingConversations, conversations } = useAppSelector(
    (state: RootState) => state.listConversation,
  )
  const { user } = useAppSelector((state: RootState) => state.auth)

  useEffect(() => {
    if (!loadingConversations && !conversations && user && searchParams) {
      const fetchConversationsWithFilters = async () => {
        const searchFilters: KeyValueType[] = []
        for (const [param, value] of searchParams.entries()) {
          if (value) {
            searchFilters.push({ key: param, value: purify(value) })
          }
        }
        dispatch(setFilters(searchFilters))
        if (searchFilters.length > 0) {
          dispatch(fetchConversations(searchFilters))
        }
      }
      fetchConversationsWithFilters()
    }
  }, [dispatch, loadingConversations, conversations, user, searchParams])
}
