import "@ingka/table/dist/style.css"
import Tabs, { Tab, TabPanel } from "@ingka/tabs"
import { Container, Grid } from "@mui/material"
import AdminUserTable from "./AdminUserTable"
import SystemPromptsEditor from "./SystemPromptEditor"

const AdminSettings = () => {
  const tabs = [
    <Tab key="admins" text="Admins" tabPanelId="admins" />,
    <Tab key="prompts" text="User prompts" tabPanelId="prompts" />,
  ]

  const panels = [
    <TabPanel key="admins" tabPanelId="admins">
      <AdminUserTable />
    </TabPanel>,
    <TabPanel key="prompts" tabPanelId="prompts">
      <SystemPromptsEditor />
    </TabPanel>,
  ]

  return (
    <Container maxWidth="xl">
    <Grid container>
      <Grid item xs={12}>
        <Tabs tabs={tabs} tabPanels={panels} defaultActiveTab="admins" />
      </Grid>
    </Grid>
    </Container>
  )
}

export default AdminSettings
