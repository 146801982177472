import { List, ListItem, Skeleton } from "@mui/material"

const ConversationsSkeleton = () => {
  return (
    <List
      style={{
        height: "calc(100vh - 16rem)",
        overflowY: "auto",
        width: "100%",
      }}
    >
      <ListItem className="conversationSkeleton">
        <Skeleton
          variant="rounded"
          width={"40%"}
          height={40}
          style={{ marginBottom: "1rem" }}
        />
        <Skeleton variant="rounded" width={"100%"} height={200} />
      </ListItem>
      <ListItem className="conversationSkeleton">
        <Skeleton
          variant="rounded"
          width={"40%"}
          height={40}
          style={{ marginBottom: "1rem" }}
        />
        <Skeleton variant="rounded" width={"100%"} height={200} />
      </ListItem>
      <ListItem className="conversationSkeleton">
        <Skeleton
          variant="rounded"
          width={"40%"}
          height={40}
          style={{ marginBottom: "1rem" }}
        />
        <Skeleton variant="rounded" width={"100%"} height={200} />
      </ListItem>
      <ListItem className="conversationSkeleton">
        <Skeleton
          variant="rounded"
          width={"40%"}
          height={40}
          style={{ marginBottom: "1rem" }}
        />
        <Skeleton variant="rounded" width={"100%"} height={200} />
      </ListItem>
      <ListItem className="conversationSkeleton">
        <Skeleton
          variant="rounded"
          width={"40%"}
          height={40}
          style={{ marginBottom: "1rem" }}
        />
        <Skeleton variant="rounded" width={"100%"} height={200} />
      </ListItem>
      <ListItem className="conversationSkeleton">
        <Skeleton variant="rounded" />
      </ListItem>
    </List>
  )
}

export default ConversationsSkeleton
