import { useState } from "react"
import Grid from "@mui/material/Grid"
import { useAppSelector } from "app/hooks"
import { RootState } from "app/store"
import MessagesSkeleton from "./MessagesSkeleton"
import "./messageList.css"
import MessageOptions from "./MessageOptions"
import MessageItem from "./MessageItem"

const MessageList: React.FC = () => {
  const { loadingConversations } = useAppSelector(
    (state: RootState) => state.listConversation,
  )
  const { messages } = useAppSelector((state: RootState) => state.messages)
  const [showOriginalText, setShowOriginalText] = useState<boolean>(true)
  const [hideAutomatedMessages, setHideAutomatedMessages] =
    useState<boolean>(true)

  const handleTranslationToggle = () => {
    setShowOriginalText((prevState) => !prevState)
  }

  const handleAutomatedMessagesToggle = () => {
    setHideAutomatedMessages((prevState) => !prevState)
  }

  return (
    <Grid container className="messageList">
      <MessageOptions
        showOriginalText={showOriginalText}
        handleTranslationToggle={handleTranslationToggle}
        handleAutomatedMessagesToggle={handleAutomatedMessagesToggle}
      />
      <Grid
        container
        rowGap={1}
        item
        xs={12}
        marginTop={1}
        className="messagesList"
        paddingTop="1rem"
      >
        {loadingConversations ? (
          <MessagesSkeleton />
        ) : (
          messages.map((message, index) => (
            <MessageItem
              key={index}
              message={message}
              index={index}
              showOriginalText={showOriginalText}
              hideAutomatedMessages={hideAutomatedMessages}
            />
          ))
        )}
      </Grid>
    </Grid>
  )
}

export default MessageList
