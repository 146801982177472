import HelperText from "@ingka/helper-text"
import Text from "@ingka/text"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
} from "@mui/material"
import { IConversation } from "app/types"
import React from "react"

interface RenderEntitiesProps {
  conversation: IConversation
}

const EntitiesAccordion: React.FC<RenderEntitiesProps> = ({ conversation }) => {
  return (
    <AccordionDetails>
      {conversation.entities.map((entity, i) => (
        <Accordion key={i} style={{ backgroundColor: "#f5f5f5" }}>
          <AccordionSummary
            aria-controls="3-content"
            id="3-header"
            expandIcon={<ExpandMoreIcon />}
          >
            <Text>{entity.entityName}</Text>
          </AccordionSummary>
          <List dense disablePadding key={entity.entityName + i}>
            {entity.values.map((v, i) => (
              <ListItem key={v + i} dense>
                <HelperText>{v}</HelperText>
              </ListItem>
            ))}
          </List>
        </Accordion>
      ))}
      {!!conversation.csatFeedback?.feedback && (
        <Accordion style={{ backgroundColor: "#f5f5f5" }}>
          <AccordionSummary
            aria-controls="3-content"
            id="3-header"
            expandIcon={<ExpandMoreIcon />}
          >
            <Text>Customer feedback</Text>
          </AccordionSummary>
          <List dense disablePadding>
            <ListItem dense>
              <HelperText>
                {conversation.csatFeedback.translated ??
                  conversation.csatFeedback.feedback}
              </HelperText>
            </ListItem>
          </List>
        </Accordion>
      )}
    </AccordionDetails>
  )
}

export default EntitiesAccordion
