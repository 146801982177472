import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts"
import Loading, { LoadingBall } from "@ingka/loading"
import Grid from "@mui/material/Grid"

interface UsageGraphProps {
  data: {
    month: string
    usage: number
    cost: number
  }[]
  isLoading: boolean
}

const UsageGraph: React.FC<UsageGraphProps> = ({ data, isLoading }) => {
  return isLoading ? (
    <Grid container>
      <Loading color="primary" labelTransitions>
        <LoadingBall size="large" />
      </Loading>
    </Grid>
  ) : (
    <LineChart width={500} height={300} data={data}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="month" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Line
        type="monotone"
        dataKey="usage"
        stroke="#8884d8"
        activeDot={{ r: 8 }}
      />
      <Line type="monotone" dataKey="cost" stroke="#82ca9d" />
    </LineChart>
  )
}

export default UsageGraph
